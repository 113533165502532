import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { PriceViewWrapper, PriceDetails, DottedLine, DetailsWrapper, PriceText, PriceMainText, PriceMainTextLabel, AddonsTitle } from './PriceView.Styles'
import H3 from '@/components/atoms/H3'
import CoinsIcon from '@/public/images/Coins.svg'
import { HANDYMAN, STORAGE, PACKING, MOVECLEAN, MOVEHELP } from '@/constants/AddonConstants'
import { OptionCard } from '@/components/molecules/OptionCard/'
import Flex from '@/components/atoms/Flex'
import Plus from '@/public/images/Plus.svg'
import Storage from '@/public/images/Storage.svg'
import Packing from '@/public/images/Packing.svg'
import Handyman from '@/public/images/Handyman.svg'
import Moveclean from '@/public/images/Moveclean.svg'
import Delivery from '@/public/images/Delivery.svg'
import { Modal } from '@/components/molecules/Modal'
import { StyledButton } from './PriceView.Styles'
import Button from '@/components/atoms/Button'
import ArrowRight from '@/public/images/ArrowRight.svg'
import { useCurrentStepContext } from '@/common/context/steps/CurrentStepProvider'
import useResponsive from '@/constants/hooks/useResponsive'
import { useMoveDateContext } from '@/common/context/moveDate/MoveDateProvider'
import { useFormAddressContext } from '@/common/context/addressForm/AddressFormProvider'
import { format } from 'date-fns'
import getPrice from '@/helpers/getPrice'
import { YES } from '@/constants/addressFormConstants'
import { useSupplierIdContext } from '@/common/context/supplierId/SupplierIdProvider'
import Spinner from '@/components/atoms/Spinner'
import { useRouter } from 'next/router'
import { useAddonContext } from '@/common/context/addon/AddonProvider'
import ActivityEnum from '@/common/enums/ActivityEnum'
export interface PriceViewProps {
  type: string
  setError: (data: ErrorObject) => void
}
export interface ViewDetailsProps {
  heading: string
  details: string
  extraHorizontalPadding?: boolean
}

const ViewDetials = ({ heading, details, extraHorizontalPadding = false }: ViewDetailsProps) => {
  return (
    <>
      <DottedLine />
      <DetailsWrapper extraHorizontalPadding={extraHorizontalPadding}>
        <H3>{heading}</H3>
        <p>{details}</p>
      </DetailsWrapper>
    </>
  )
}

const formatNumber = (num: number) => String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ')

const PriceView = ({ type, setError }: PriceViewProps) => {
  const router = useRouter()
  const { organizationId } = router.query
  const { movingDate } = useMoveDateContext()
  const { t } = useTranslation(['address', 'common', 'summary', 'addon', 'priceview', 'errors', 'dateNames'])
  const [priceDetails, setPriceDetails] = useState<PriceDetails>()
  const [service, setServiceType] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [getService, setService] = useState({ STORAGE: false, PACKING: false, HANDYMAN: false, MOVECLEAN: false, MOVEHELP: false })
  const { currentStep, serviceType, incrementStep, addons, addAddons, removeAddons } = useCurrentStepContext()
  const { newAddressInfo, currentAddressInfo } = useFormAddressContext()
  const [isDataFetched, setIsDataFetched] = useState(false)
  const { updateSupplierId } = useSupplierIdContext()
  // const { updateAdditionalInfo } = useAddonContext()
  const { isTabletPortraitOrGreater } = useResponsive()

  const openModal = (selectedServiceType: string) => {
    setShowModal((prev) => !prev)
    setServiceType(selectedServiceType)
  }

  useEffect(() => {
    const currentServiceType = addons.includes(MOVECLEAN) || addons.includes(MOVEHELP) ? 'movehelpCombined' : type
    const getPriceResponse = async () => {
      const priceData = await getPrice(currentServiceType, {
        organizationId: organizationId as string,
        deliveryDate: format(movingDate, 'yyyy-MM-dd'),
        fromResidence: {
          street: `${currentAddressInfo.street} ${currentAddressInfo.streetNumber}`,
          city: currentAddressInfo.city,
          zip: currentAddressInfo.zip,
          elevator: currentAddressInfo.elevatorSize,
          size: currentAddressInfo.residenceSize,
          floor: currentAddressInfo.floor,
          sizeOfAdditionalSpace: currentAddressInfo.sizeOfAdditionalSpace,
          residenceType: currentAddressInfo.residenceType,
          isLimitedAccess: currentAddressInfo.limitedAccessibility === YES ? true : false,
        },
        toResidence: {
          street: `${newAddressInfo.street} ${newAddressInfo.streetNumber}`,
          city: newAddressInfo.city,
          zip: newAddressInfo.zip,
          elevator: newAddressInfo.elevatorSize,
          size: newAddressInfo.residenceSize,
          floor: newAddressInfo.floor,
          sizeOfAdditionalSpace: newAddressInfo.sizeOfAdditionalSpace,
        },
      })
      if (!priceData?.price) {
        setError({
          isError: true,
          title: t('errors:callYou'),
          description: t('errors:callYouDescription'),
          subTitle: priceData?.messageKey
            ? t<string>(`errors:MESSAGE_KEYS.${priceData?.messageKey}`)
            : priceData?.message
            ? t<string>(`errors:MESSAGE_KEYS.${priceData?.message}`)
            : '',
        })
      } else {
        setPriceDetails(priceData)
        setIsDataFetched(true)
        updateSupplierId(priceData?.supplierId || '')
      }
    }
    getPriceResponse()
  }, [addons])

  const updateStepCount = () => {
    // updateAdditionalInfo({ storage: getService.STORAGE, handyman: getService.HANDYMAN, packing: getService.PACKING, cleaning: getService.MOVECLEAN, moving: getService.MOVEHELP })
    incrementStep()
  }
  ActivityEnum
  const selectService = (selectedProduct: string) => {
    if (selectedProduct === 'storage' && !getService.STORAGE) {
      setService({ ...getService, STORAGE: true })
    } else if (selectedProduct === 'handyman' && !getService.HANDYMAN) {
      setService({ ...getService, HANDYMAN: true })
    } else if (selectedProduct === 'packing' && !getService.PACKING) {
      setService({ ...getService, PACKING: true })
    } else if (selectedProduct === 'moveclean' && !getService.MOVECLEAN) {
      setService({ ...getService, MOVECLEAN: true })
      const newPriceDetails = { ...priceDetails }
      if (newPriceDetails.price?.addons.service?.type === 'cleaning') {
        newPriceDetails.price.total += newPriceDetails.price?.addons.service?.price as unknown as number
      }
    } else if (selectedProduct === 'movehelp' && getService.MOVEHELP) {
      setService({ ...getService, MOVEHELP: false })
      const newPriceDetails = { ...priceDetails }
      if (newPriceDetails.price?.addons.service?.type === 'moving') {
        newPriceDetails.price.total += newPriceDetails.price?.addons.service?.price as unknown as number
      }
    }
    addAddons(selectedProduct)
  }

  const unSelectService = (selectedProduct: string) => {
    if (selectedProduct === 'storage' && getService.STORAGE) {
      setService({ ...getService, STORAGE: false })
    } else if (selectedProduct === 'handyman' && getService.HANDYMAN) {
      setService({ ...getService, HANDYMAN: false })
    } else if (selectedProduct === 'packing' && getService.PACKING) {
      setService({ ...getService, PACKING: false })
    } else if (selectedProduct === 'moveclean' && getService.MOVECLEAN) {
      setService({ ...getService, MOVECLEAN: false })
      const newPriceDetails = { ...priceDetails }
      if (newPriceDetails.price?.addons.service?.type === 'cleaning') {
        newPriceDetails.price.total -= newPriceDetails.price?.addons.service?.price as unknown as number
      }
    } else if (selectedProduct === 'movehelp' && getService.MOVEHELP) {
      setService({ ...getService, MOVEHELP: false })
      const newPriceDetails = { ...priceDetails }
      if (newPriceDetails.price?.addons.service?.type === 'moving') {
        newPriceDetails.price.total -= newPriceDetails.price?.addons.service?.price as unknown as number
      }
    }
    removeAddons(selectedProduct)
  }
  const moveHelpCombined = (addons.includes(MOVECLEAN) && type === ActivityEnum.MOVEHELP) || (addons.includes(MOVEHELP) && type === ActivityEnum.MOVECLEAN)
  return (
    <PriceViewWrapper>
      {!isDataFetched && (
        <Flex direction="column" justifyContent="center" alignItems="center">
          <Spinner scale={2} />
        </Flex>
      )}
      {isDataFetched && (
        <>
          <Modal onSelect={selectService} type={service} showModal={showModal} setShowModal={setShowModal} />
          <PriceDetails>
            <PriceMainText>
              <PriceMainTextLabel>{t('summary:PRICE_VIEW.title')}:</PriceMainTextLabel> <CoinsIcon />
              <PriceText>
                {(addons.includes(MOVECLEAN) && type === ActivityEnum.MOVEHELP) || (addons.includes(MOVEHELP) && type === ActivityEnum.MOVECLEAN)
                  ? `${formatNumber(Math.round(((priceDetails?.price?.total as number) || 0) + ((priceDetails?.price?.addons?.service?.price as unknown as number) || 0)))} SEK`
                  : `${formatNumber(priceDetails?.price?.total as number)} SEK`}
              </PriceText>
            </PriceMainText>
            {moveHelpCombined && (
              <ViewDetials
                heading={t('summary:PRICE_VIEW.movingAndCleaning')}
                details={`${formatNumber(
                  Math.round(((priceDetails?.price?.fixed as number) || 0) + ((priceDetails?.price?.addons?.service?.price as unknown as number) || 0))
                )} SEK`}
                extraHorizontalPadding={true}
              />
            )}
            {!addons.includes(MOVECLEAN) && type === ActivityEnum.MOVEHELP && (
              <ViewDetials heading={t('summary:PRICE_VIEW.moveHelp')} details={`${formatNumber(priceDetails?.price?.fixed as number)} SEK`} extraHorizontalPadding={true} />
            )}
            {!addons.includes(MOVEHELP) && type === ActivityEnum.MOVECLEAN && (
              <ViewDetials heading={t('summary:PRICE_VIEW.movecleaning')} details={`${formatNumber(priceDetails?.price?.fixed as number)} SEK`} extraHorizontalPadding={true} />
            )}
            {!!priceDetails?.price?.addons?.holiday && priceDetails?.price?.addons?.holiday > 0 && (
              <ViewDetials heading={t('summary:PRICE_VIEW.weekendAddons')} details={`${formatNumber(priceDetails?.price?.addons?.holiday)} SEK`} extraHorizontalPadding={true} />
            )}

            {addons.includes(PACKING) && (
              <ViewDetials heading={t('summary:PRICE_VIEW.packing')} details={t('summary:PRICE_VIEW.youWillBeContacted')} extraHorizontalPadding={true} />
            )}
            {addons.includes(HANDYMAN) && (
              <ViewDetials heading={t('summary:PRICE_VIEW.handyman')} details={t('summary:PRICE_VIEW.youWillBeContacted')} extraHorizontalPadding={true} />
            )}
            {addons.includes(STORAGE) && (
              <ViewDetials heading={t('summary:PRICE_VIEW.storage')} details={t('summary:PRICE_VIEW.youWillBeContacted')} extraHorizontalPadding={true} />
            )}
            <AddonsTitle>{t('summary:PRICE_VIEW.more')}</AddonsTitle>
            <Flex direction={isTabletPortraitOrGreater ? 'row' : 'column'} justifyContent="center" alignItems={'center'}>
              <Flex direction="column" justifyContent="center" alignItems={'center'} className="additionalservices">
                {type === ActivityEnum.MOVEHELP && (
                  <OptionCard
                    color="lightblue"
                    borderColor="blue"
                    type={serviceType}
                    isServiceSelected={addons.includes(MOVECLEAN)}
                    price={priceDetails?.price?.addons?.service?.price ? formatNumber(priceDetails?.price?.addons?.service?.price as unknown as number) : ''}
                    onclick={() => openModal(MOVECLEAN)}
                    title={t('summary:PRICE_VIEW.movecleaning')}
                    plusIcon={<Plus />}
                    serviceIcon={<Moveclean />}
                    unSelect={() => unSelectService(MOVECLEAN)}
                  />
                )}
                {type === ActivityEnum.MOVECLEAN && (
                  <OptionCard
                    color="lightblue"
                    borderColor="blue"
                    type={serviceType}
                    isServiceSelected={addons.includes(MOVEHELP)}
                    price={priceDetails?.price?.addons?.service?.price ? formatNumber(priceDetails?.price?.addons?.service?.price as unknown as number) : ''}
                    onclick={() => openModal(MOVEHELP)}
                    title={t('summary:PRICE_VIEW.moveHelp')}
                    plusIcon={<Plus />}
                    serviceIcon={<Delivery />}
                    unSelect={() => unSelectService(MOVEHELP)}
                  />
                )}
                <OptionCard
                  color="lightgreen"
                  borderColor="green"
                  type={serviceType}
                  isServiceSelected={addons.includes(PACKING)}
                  onclick={() => openModal(PACKING)}
                  title={t('summary:PRICE_VIEW.packing')}
                  plusIcon={<Plus />}
                  serviceIcon={<Packing />}
                  unSelect={() => unSelectService(PACKING)}
                />
              </Flex>
              <Flex direction="column" justifyContent="center" alignItems={'center'} className="additionalservices">
                <OptionCard
                  color="lightyellow"
                  borderColor="yellow"
                  type={serviceType}
                  isServiceSelected={addons.includes(HANDYMAN)}
                  onclick={() => openModal(HANDYMAN)}
                  title={t('summary:PRICE_VIEW.handyman')}
                  plusIcon={<Plus />}
                  serviceIcon={<Handyman />}
                  unSelect={() => unSelectService(HANDYMAN)}
                />
                <OptionCard
                  color="lightgrey"
                  borderColor="grey"
                  onclick={() => openModal(STORAGE)}
                  type={serviceType}
                  isServiceSelected={addons.includes(STORAGE)}
                  title={t('summary:PRICE_VIEW.storage')}
                  plusIcon={<Plus />}
                  serviceIcon={<Storage />}
                  unSelect={() => unSelectService(STORAGE)}
                />
              </Flex>
            </Flex>
          </PriceDetails>
          <Flex direction={'row'} justifyContent={'center'} className="continueBtn">
            <StyledButton>
              <Button
                onClick={updateStepCount}
                text={t('common:continue')}
                variant="primaryAlt"
                iconRight={<ArrowRight />}
                disabled={!movingDate && currentStep === 1 ? true : false}
              />
            </StyledButton>
          </Flex>
          <Flex direction={'row'} justifyContent={'center'}>
            <p>
              <strong>{t('dateNames:next_step')}:</strong> {t('common:contactDetails')}{' '}
            </p>
          </Flex>
        </>
      )}
    </PriceViewWrapper>
  )
}

export default PriceView
