import React, { ReactNode } from 'react'
import { isSafari, isIOS } from 'react-device-detect'
import { styled } from 'stitches.config'
import { mergeRefs } from '@/common/helpers/mergeRefs'
import ArrowDown from '@/public/images/Arrow-Up.svg'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import useResizeObserver from '@react-hook/resize-observer'
import { keyframes } from '@stitches/react'

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
})

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
})

const StyledItem = styled(AccordionPrimitive.Item, {
  overflow: 'hidden',
  marginTop: 10,
  borderRadius: '$borderRadiusSmall',
  a: { color: '#FFFFFF', fontWeight: 700, textDecoration: 'underline' },
  '&:focus-within': {
    position: 'relative',
    zIndex: 1,
  },
})

const StyledHeader = styled(AccordionPrimitive.Header, {
  all: 'unset',
  display: 'flex',
  position: 'relative',
})

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: 'unset',
  fontFamily: 'inherit',
  backgroundColor: 'transparent',
  padding: '8px 24px',
  height: 88,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 15,
  borderTopLeftRadius: '$borderRadiusMain',
  borderTopRightRadius: '$borderRadiusMain',
  variants: {
    lessHeight: {
      true: {
        height: 38,
      },
    },
  },
  '&:hover': {
    cursor: 'pointer',
  },
  '@sm': {
    fontSize: 20,
  },
  lineHeight: 1,
  '&[data-state="closed"]': { backgroundColor: '$secondaryExtraDark4' },
  '&[data-state="open"]': { backgroundColor: '$secondaryLight' },
})

const StyledContent = styled(AccordionPrimitive.Content, {
  overflow: 'hidden',
  fontSize: 15,
  '@sm': {
    fontSize: 18,
  },
  color: '$whiteMain',
  backgroundColor: '$whiteMain',
  borderBottomRightRadius: '$borderRadiusMain',
  borderBottomLeftRadius: '$borderRadiusMain',
  variants: {
    iOS: {
      true: {
        height: 'auto',
        '&[data-state="open"]': {
          animation: 'unset',
          '-webkit-animation': 'unset',
        },
        '&[data-state="closed"]': {
          animation: 'unset',
          '-webkit-animation': 'unset',
        },
      },
      false: {
        '&[data-state="open"]': {
          animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
          '-webkit-animation': `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
        },
        '&[data-state="closed"]': {
          animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
          '-webkit-animation': `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
        },
      },
    },
  },
})

const StyledContentText = styled('div', {
  variants: {
    noPadding: {
      true: {
        padding: '0 px',
      },
      false: {
        padding: '15px 20px',
      },
    },
  },
})

const StyledChevron = styled(ArrowDown, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  flexShrink: 0,
  '[data-state=open] &': { transform: 'rotate(180deg)' },
  ' path': {
    stroke: 'black',
  },
})

// Exports
export const Accordion = styled(AccordionPrimitive.Root, {})
export const AccordionItem = StyledItem
export const AccordionTrigger = React.forwardRef<HTMLButtonElement, { children: ReactNode; onClick?: React.MouseEventHandler<HTMLButtonElement>; lessHeight?: boolean }>(
  ({ children, ...props }, forwardedRef) => (
    <StyledHeader>
      <StyledTrigger {...props} ref={forwardedRef}>
        {children}
        <StyledChevron aria-hidden />
      </StyledTrigger>
    </StyledHeader>
  )
)
export const AccordionContent = React.forwardRef<HTMLDivElement, { children: ReactNode; noPadding?: boolean; className?: string }>(
  ({ children, noPadding = false, ...props }, forwardedRef) => {
    const ref = React.useRef<HTMLDivElement>(null)
    const contentRef = React.useRef<HTMLDivElement>(null)
    const mergedRef = mergeRefs([ref, forwardedRef])
    const isIosOrDesktopSafari = isIOS || isSafari

    useResizeObserver(contentRef.current, (entry) => {
      ref.current?.style.setProperty('--radix-collapsible-content-height', `${entry.contentRect.height}px`)
    })

    return (
      <StyledContent iOS={isIosOrDesktopSafari} {...props} ref={mergedRef} data-abc="abvc">
        <StyledContentText ref={contentRef} noPadding={noPadding}>
          {children}
        </StyledContentText>
      </StyledContent>
    )
  }
)
