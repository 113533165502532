type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
type ApiFetchAsync = <T>(url: string, method: HttpMethod, body?: object) => Promise<T>
type ApiExecutor = {
  fetch: ApiFetchAsync
}

const useApi = (): ApiExecutor => {
  const fetcher = async <T>(url: string, method: HttpMethod, body?: object): Promise<T> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}${url}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: body ? JSON.stringify(body) : undefined,
    })
    const data = await response.json()
    return data as T
  }

  return { fetch: fetcher }
}

export default useApi
