import { styled } from 'stitches.config'

const H2 = styled('h2', {
  fontSize: 24,
  fontWeight: 700,
  marginBottom: 10,
  marginTop: 0,
  '@sm': {
    fontSize: 32,
  },
  variants: {
    textAlign: {
      center: {
        textAlign: 'center',
      },
    },
  },
})
export default H2
