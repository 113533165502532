import { keyframes, styled } from 'stitches.config'

const fadeOut = keyframes({
  '100%': { opacity: 1 },
  '0%': { opacity: 0 },
})
const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
})

export const FooterWrapper = styled('div', {
  width: '100%',
  bottom: 0,
})

export const ExpandedExpertImageWrapper = styled('div', {
  height: '100%',
  variants: {
    isFooterOpen: {
      true: {
        animation: `${fadeIn} 2s`,
      },
    },
  },
})

export const ExpertImageWrapper = styled('div', {
  height: '100%',
  variants: {
    isFooterOpen: {
      false: {
        animation: `${fadeOut} 2s`,
      },
    },
  },
})

export const ExpertImage = styled('div', {
  position: 'absolute',
  left: 10,
  bottom: -20,
})

export const ExpandedExpertImage = styled('div', {
  position: 'absolute',
  left: 1,
  bottom: -10,
})

export const VectorUpWrapper = styled('div', {
  cursor: 'pointer',
})

export const ExpandedFooter = styled('div', {
  height: '100%',
  marginLeft: '25px',
  marginRight: '25px',
  textAlign: 'left',
  h4: {
    margin: '0px 0px 10px 0px',
  },
  p: {
    margin: 0,
    lineHeight: '21px',
    fontSize: '$2',
  },

  variants: {
    isFooterOpen: {
      true: {
        'h3, h4, p': {
          animation: `${fadeIn} 2s`,
        },
      },
    },
  },
})
