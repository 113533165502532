import { createStitches } from '@stitches/react'
import { responsiveStiches } from './constants/responsive'

export const { styled, css, getCssText, keyframes, theme, globalCss } = createStitches({
  media: {
    xxs: responsiveStiches.smallMobile,
    xs: responsiveStiches.mobile,
    sm: responsiveStiches.tabletPortrait,
    md: responsiveStiches.tabletLandscape,
    lg: responsiveStiches.desktop,
    xl: responsiveStiches.desktopXL,
    xxl: responsiveStiches.desktopXXL,
  },
  theme: {
    colors: {
      primaryLight: '#9ee0d5',
      primaryMain: '#51c8b4',
      orangeLight: '#FFEEC0',
      primaryLightDark: '#37ae9a',
      primaryDark: '#1f6156',
      primaryContrastText: '#ffffff',
      secondaryLight: '#3879ad',
      secondaryMain: '#214766',
      secondaryDark: '#011627',
      secondaryMainDark: '#112E46',
      secondaryContrastText: '#ffffff',
      secondaryExtraExtraLight: '#eaf2f8',
      secondaryExtraLittleLight: '#2b567c',
      secondaryExtraDark2: '#1a1a19',
      secondaryExtraDark3: '#060d13',
      secondaryExtraDark4: '#1b3951',
      secondary500: '#306490',
      accentLighter: '#FFF1E5',
      accentLight: '#FFD4B3',
      accentMain: '#ffa65f',
      accentIcon: '#FFA65F',
      darkAccentMain: '#E17A27',
      backgroundDefault: '#f8faf9',
      inactiveText: '#959495',
      inactivesSuperLight: '#ffebe8',
      inactiveExtraLight: '#fafafc',
      inactiveVeryLight: '#f8faf9',
      inactiveQuiteLight: '#f5f5f7',
      inactiveLight: '#f2f2ef',
      inactiveMain: '#eeeef0',
      inactiveDark: '#767678',
      inactiveGreyLight: '#BEBEC0',
      inactiveQuiteDark: '#767678',
      inactiveExtraDark: '#61615f',
      grey08: '#626263',
      gray07: '#808080',
      inactiveSuperDark: '#222223',
      inactiveContrastText: '#000000',
      tertiaryLight: '#9ee0d5',
      tertiaryLightBackground: '#E7FBF8',
      tertiaryMain: '#51c8b4',
      tertiaryLightDark: '#37ae9a',
      tertiaryDark: '#1f6156',
      tertiaryVeryLight: '#c5ede6',
      tertiaryContrastText: '#ffffff',
      blackMain: '#000',
      whiteMain: '#fff',
      errorRed: '#f25a43',
      radioBorder: '#9f9fa1',
      mainFontColor: '#fff',
      settings: '#30648F',
      settingsBackground: '#244D71',
      partnerBackground: '#214766',
      partnerColor: '#fff',
      settingsBorder: '#214766',
      gray: '#EEEEEE',
      settingsStroke: 'rgba(255, 255, 255, 0.5)',
      textMain: '#214766',
      ghostBackground: '#AFDCFF',
      renovationColor: '#3879AD1A',
    },
    space: {
      1: '5px',
      2: '10px',
      3: '15px',
      4: '20px',
      5: '25px',
    },
    fontSizes: {
      small: '10px',
      1: '12px',
      2: '13px',
      3: '14px',
      4: '15px',
      5: '16px',
      6: '18px',
      7: '20px',
      8: '22px',
    },
    fonts: {
      main: 'Gilroy',
      secondary: 'PrumoDeckW00-Black',
    },
    fontWeights: {
      black: '900',
      bolder: '800',
      bold: '700',
      semiBold: '600',
      normal: '400',
      extraSmall: '100',
    },
    lineHeights: {},
    letterSpacings: {},
    sizes: {},
    borderWidths: {},
    borderStyles: {},
    radii: { borderRadiusSmall: '8px', borderRadiusMain: '10px', input: '8px', button: '96px', radio: '20px', extraSmall: '1.9px', product: '12px', big: '100px', medium: '50px' },
    shadows: {},
    zIndices: {
      1: 1,
      2: 2,
      3: 3,
    },
    transitions: {},
  },
})

export const globalStyles = globalCss({})
