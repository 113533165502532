import React, { useState, useRef, ReactElement } from 'react'
import * as Tooltip from '@radix-ui/react-tooltip'
import { Trigger, Background, Content, Arrow } from './Popup.style'
import { useClickAway } from 'react-use'
import FlyttsmartLogoOnly from '@/public/images/FlyttsmartLogoOnly.svg'
import Close from '@/public/images/Close.svg'
import Flex from '../Flex'
export interface PopupProps {
  children: ReactElement
  title?: string
  text: ReactElement | string | null
  textMargin?: boolean
  noUnderline?: boolean
}

const Popup = ({ children, title, text, textMargin, noUnderline }: PopupProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const buttonRef = useRef(null)
  const tooltipRef = useRef(null)
  // the ref for the arrow must be a callback ref
  useClickAway(tooltipRef || buttonRef, () => {
    setShowTooltip(false)
  })

  if (!text) {
    return null
  }

  return (
    <>
      <Background visible={showTooltip} onClick={() => setShowTooltip(!showTooltip)} />
      <Tooltip.Provider>
        <Tooltip.Root open={showTooltip}>
          <Trigger
            noUnderline={noUnderline}
            ref={buttonRef}
            data-testid="popup-button"
            onClick={() => {
              setShowTooltip(!showTooltip)
            }}
          >
            {children}
          </Trigger>
          <Tooltip.Portal>
            <Content textMargin={textMargin} data-testid="popup-content">
              {title && (
                <Flex direction="row" justifyContent="space-between">
                  <Flex direction="row">
                    <FlyttsmartLogoOnly width={25} height={25} />
                    <p className="title">{title}</p>
                  </Flex>
                  <div>
                    <Close
                      width={25}
                      height={25}
                      onClick={() => {
                        setShowTooltip(false)
                      }}
                    />
                  </div>
                </Flex>
              )}
              {text}
              <Arrow id="arrow" />
            </Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    </>
  )
}

export default Popup
