import useApi from 'helpers/api'

const getOrganizationUser = async (organizationId: string) => {
  try {
    const api = useApi()
    const response = await api.fetch<OrganizationUser>(`/widget/organization/user/${organizationId}`, 'GET')
    return response
  } catch (e) {
    console.log(e)
  }
}

export default getOrganizationUser
