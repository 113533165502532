// import Footer from '@/components/molecules/Footer'
import ActivityEnum from '@/common/enums/ActivityEnum'
import Footer from '@/components/molecules/Footer'
import TopNav from '@/components/molecules/TopNav'
import { OrderWidgetTemplate } from '@/templates/OrderWidgetTemplate/OrderWidgetTemplate'
import { NextPage, GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'

const Home: NextPage = () => {
  //! Maybe this changes but for now I think we need to pick up the type the widget user wants via a query and the possibly the step in the flow they are on
  //! if no step is provided then it's step 1 as default
  const router = useRouter()
  const { type, step, isQuotation } = router.query
  const isQuotationFormatted = String(isQuotation).toLowerCase() === 'true'

  return (
    <div id="wrapper">
      <TopNav />
      <OrderWidgetTemplate activeStep={step ? Number(step) : 1} type={type as string} isQuotation={isQuotationFormatted} />
      {process.env.NEXT_PUBLIC_SHOW_FOOTER === 'true' ? <Footer /> : <></>}
    </div>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale, defaultLocale }) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- defaultLocale is set in nextjs config and will always have a value
  const pageLocale = locale || (defaultLocale as string)
  const translate = await serverSideTranslations(locale || (defaultLocale as string), ['address', 'errors', 'dateNames', 'footer', 'common', 'contactDetails', 'summary', 'addon'])
  return {
    props: {
      ...translate,
      locale: pageLocale,
    },
  }
}

export default Home
