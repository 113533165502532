import React, { useEffect, useState } from 'react'
import { LogoSection, TopNavWrapper, VectorSection } from './TopNav.styles'
import VectorLeft from '@/public/images/VectorLeft.svg'
import FlyttsmartLogoDark from '@/public/images/FlyttsmartLogoDark.svg'
import Flex from '@/components/atoms/Flex'
import { useCurrentStepContext } from '@/common/context/steps/CurrentStepProvider'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import ActivityEnum from '@/common/enums/ActivityEnum'
import useResponsive from '@/constants/hooks/useResponsive'

const TopNav = () => {
  const { isTabletPortraitOrGreater } = useResponsive()
  const router = useRouter()
  const { type } = router.query
  const { decrementStep, currentStep } = useCurrentStepContext()
  const [getTitle, setTitle] = useState('One')
  const [totalSteps, setTotalSteps] = useState<number | null>(1)

  const { t } = useTranslation(['common', 'address', 'contactDetails', 'summary', 'dateNames'])

  useEffect(() => {
    if (type === ActivityEnum.MOVEHELP || type === ActivityEnum.MOVEHELP_COMBINED) {
      setTotalSteps(6)
    } else {
      setTotalSteps(5)
    }
  }, [type])

  const updateStepCount = () => {
    if (currentStep > 1) {
      decrementStep()
    }
  }

  useEffect(() => {
    updateTitle(currentStep, type)
  }, [currentStep])

  const updateTitle = (stepCount: number, serviceType: any): void => {
    switch (stepCount) {
      case 1:
        setTitle(t('dateNames:select_date').toString())
        break
      case 2:
        serviceType === ActivityEnum.MOVEHELP ? setTitle(t('dateNames:current_address').toString()) : setTitle(t('address:NEWADDRESS.yourAddress').toString())
        break
      case 3:
        serviceType === ActivityEnum.MOVEHELP ? setTitle(t('address:NEWADDRESS.newAddress').toString()) : setTitle(t('summary:PRICE_VIEW.priceProposal').toString())
        break
      case 4:
        serviceType === ActivityEnum.MOVEHELP ? setTitle(t('summary:PRICE_VIEW.priceProposal').toString()) : setTitle(t('contactDetails:contactDetails').toString())
        break
      case 5:
        serviceType === ActivityEnum.MOVEHELP ? setTitle(t('contactDetails:contactDetails').toString()) : setTitle(t('address:NEWADDRESS.yourAddress').toString())
        break
      default:
        setTitle('')
    }
  }

  return (
    <TopNavWrapper>
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <Flex direction="row" justifyContent={'center'} alignItems={'center'} onClick={updateStepCount}>
          <VectorSection>
            <VectorLeft width={13} height={22} />
          </VectorSection>
          <div className="backBtnLabelContainer">
            <p className="backBtnLabel">{t('common:back')}</p>
          </div>
        </Flex>
        <Flex direction="row" justifyContent={'center'} alignItems={'center'}>
          {isTabletPortraitOrGreater && (
            <div style={{ marginLeft: '5px' }}>
              {/* <p className="stepperText">{`${t('common:step')} ${currentStep} ${t('common:stepOutOf')} ${totalSteps}: ${getTitle}  `}</p> */}
              <p className="stepperText">
                <strong>{`${t('common:step')} ${currentStep} ${t('common:stepOutOf')} ${totalSteps} :  `}</strong>

                {`${getTitle}`}
              </p>
            </div>
          )}
        </Flex>

        <LogoSection>
          <Flex direction="row" alignItems="center">
            <p>Powered by</p>
            <FlyttsmartLogoDark width={84} height={15} />
          </Flex>
        </LogoSection>
      </Flex>
    </TopNavWrapper>
  )
}

export default TopNav
