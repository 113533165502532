import { styled } from 'stitches.config'

export const OptionCardWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  width: '318px',
  height: '77px',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '15px',
  borderColor: '#BEBEC0',
  borderWidth: '2px',
  borderStyle: 'solid',
  padding: '0 10px 0 10px ',
  margin: '15px 0px 15px 0px',
  position: 'relative',
  variants: {
    color: {
      lightblue: {
        backgroundColor: '#F5FBFF',
      },
      lightgreen: {
        backgroundColor: '#ECF9F7',
      },
      lightyellow: {
        backgroundColor: '#FFFAE7',
      },
      lightgrey: {
        backgroundColor: '#FAFAFC',
      },
    },
    borderColor: {
      blue: {
        borderColor: '#AFDCFF',
      },
      green: {
        borderColor: '#9EE0D5',
      },
      yellow: {
        borderColor: '#FFDC60',
      },
      grey: {
        borderColor: '#BEBEC0',
      },
    },
  },

  '@sm': {
    display: 'flex',
    flexDirection: 'row',
    width: '318px',
    height: '77px',
    justifyContent: 'space-between',
    borderRadius: '15px',
    borderWidth: 'thin',
    borderStyle: 'solid',
    margin: '20px 10px 0px 10px',
  },
})

export const MainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-around',
  '@sm': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
})

export const PlusIconWrapper = styled('div', {
  width: '15px',
  height: '15px',
  marginRight: '10px',
})

export const ServiceIconWrapper = styled('div', {
  marginLeft: '15px',
  '@sm': {
    position: 'relative',
    right: '10px',
  },
})

export const LeadingText = styled('div', {
  fontSize: '$3',
  color: '$secondaryMain',
  fontWeight: '$bold',
  lineHeight: '18px',
  margin: '0 0 0 25px',
  textTransform: 'capitalize',
})

export const TrailingText = styled('div', {
  fontSize: '$2',
  color: '$secondaryMain',
  fontWeight: '$bold',
  lineHeight: '18px',
  sm: {
    fontSize: '$2',
    color: '$secondaryMain',
    fontWeight: '$normal',
    lineHeight: '18px',
  },
})

export const ChipWrapper = styled('div', {
  height: '30px',
  position: 'absolute',
  top: '-15px',
  right: '25px',
})

export const ClickContainer = styled('div', {
  cursor: 'pointer',
  display: 'flex',
})
