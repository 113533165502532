import { M } from 'msw/lib/glossary-de6278a9'
import { styled } from 'stitches.config'

export const TopNavWrapper = styled('div', {
  padding: '10px 10px 0px 15px',
  WebkitFixed: 'fixed',
  position: 'fixed',
  background: '#FAFAFC',
  zIndex: 10,
  top: 0,
  width: '100%',
  '.backBtnLabelContainer':{
    marginLeft:'24px',
    cursor: 'pointer',

  },
  '.backBtnLabel': {
    color: '$secondaryMain',
    fontSize: '$5',
    lineHeight: '20px',
  },
  '.stepperText': {
    color: '$secondaryMain',
    fontSize: '$5',
    lineHeight: '20px',
  },
})

export const VectorSection = styled('div', {
  cursor: 'pointer',
})

export const LogoSection = styled('div', {
  p: {
    fontSize: '$3',
    marginRight: '5px',
    lineHeight: '16.5px',
  },
})
