import Flex from '@/components/atoms/Flex'
import H3 from '@/components/atoms/H3'
import { styled } from 'stitches.config'
import { AccordionItem } from '@/components/molecules/Accordion'

export const ContactDetailsWrapper = styled('div', {
  color: '$secondaryMain',
  width: '100%',
  '.contactDetailsInfoText': {
    fontSize: '$5',
  },
  '.secuirtyNoText': {
    fontWeight: '700',
    color: '$secondaryMain',
    textDecoration: 'underline',
    textDecorationColor: '$secondaryMain',
  },
  p: {
    fontSize: '$2',
  },
})

export const MainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  '@sm': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'start',
  },
})

export const SubContainer = styled('div', {
  width: '100%',
  padding: '10px 5px 0px 5px',
  '@sm': {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 25px 0px 25px',
  },
})

export const AccordionItemView = styled('div', {
  marginTop: '20px',
})

export const AccordionItemWrapper = styled('div', {
  marginTop: '10px',
  boxShadow: '0px 8px 22px 0px #0000000F',
  borderRadius: '$borderRadiusMain',
})

export const AccordionItemHeading = styled('p', {
  fontWeight: '700',
})

export const ChangeDetailsText = styled('p', {
  padding: '15px',
  fontWeight: '700',
  cursor: 'pointer',
})

export const AccordionContentWrapper = styled('div', {
  color: '$secondaryMain',

  textAlign: 'center',
})

export const StyledAccordionItem = styled(AccordionItem, {
  borderRadius: '$borderRadiusMain',
})

export const AccordionButtonWrapper = styled('div', {
  button: {
    background: 'linear-gradient(201.42deg, #FAFAFC 28.41%, #F5F5F7 63.28%)',
    borderRadius: '14.1333px',
    border: '1.17778px solid #EEEEF0',
    borderColor: '0px 9.42222px 25.9111px rgba(0, 0, 0, 0.06)',
    padding: '0 20px 0 0',
    '&[data-state="open"]': { backgroundColor: '$whiteMain' },
    '&[data-state="closed"]': { backgroundColor: '$whiteMain' },
  },
})

export const IconWrapper = styled('div', {
  margin: '25px',
  width: '50px',
})

export const TextWrapper = styled('div', {
  width: '100%',
  textAlign: 'left',
  color: '$inactiveSuperDark',
  H3: {
    fontSize: '$5',
    fontWeight: 400,
    marginBottom: '8px',
  },
  p: {
    fontSize: '$3',
    marginBottom: 0,
    marginTop: 0,
  },
})

export const DottedLine = styled('div', {
  borderTop: '1px dashed #E1E1E3',
})

export const DetailsWrapper = styled('div', {
  display: 'flex',
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '$whiteMain',
  padding: '20px',
  height: '40px',
  H3: {
    fontSize: '$2',
    fontWeight: 700,
    marginBottom: 0,
  },
  p: {
    fontSize: '$2',
    color: 'black',
  },
  variants: {
    extraHorizontalPadding: {
      true: {
        paddingLeft: '30px',
        paddingRight: '30px',
      },
    },
  },
})
export const PopupWrapper = styled('div', {
  textAlign: 'right',
})

export const PopupText = styled('p', {
  fontSize: '15px',
  lineHeight: '17px',
  whiteSpace: 'pre-line',
})

export const ContactDetailsText = styled('p', {
  color: '#767678',
  fontWeight: '400',
  margin: '0 12px',
})

export const ServiceDetailsText = styled(H3, {
  fontSize: '$3',
  fontWeight: '700',
  textAlign: 'left',
  margin: '15px 10px 0',
})

export const PermissionWrapper = styled(Flex, {
  justifyContent: 'start',
  alignItems: 'center',
  width: '100%',
  margin: '0 0 20px',
  lineHeight: '1.6',
})

export const PermissionText = styled(H3, {
  fontSize: '$3',
  textAlign: 'left',
  margin: '0px',
  padding: '5px 0 0 10px',
  fontWeight: '400',
  a: {
    fontWeight: '700',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
})

export const InputWrapper = styled('div', {
  margin: '15px 0px',
  width: '100%',
  textAlign: 'left',
})

export const Seperator = styled('div', {
  backgroundColor: '#EEEEF0',
  width: '100%',
  height: '1px',
  marginTop: '10px',
  marginBottom: '20px',
})

export const StyledButton = styled('div', {
  width: '276px',
  height: '54px',
  borderRadius: '31px',
  margin: '0 auto 0',
  '@sm': {
    width: '276px',
    height: '48px',
    borderRadius: '31px',
    margin: '0',
  },
})

export const AdditionalServiceWrapper = styled('div', {
  marginTop: '30px',
  '.additionalServiceHeading': {
    fontWeight: '700',
    marginBottom: '$1',
  },
})
