import { styled } from 'stitches.config'
import * as Tooltip from '@radix-ui/react-tooltip'

export const Background = styled('div', {
  position: 'fixed',
  height: '100vh',
  width: '100%',
  background: 'rgba(0, 0, 0, 0.3)',
  top: 0,
  left: 0,
  zIndex: 999999,
  display: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
  variants: {
    visible: {
      true: {
        display: 'block',
      },
    },
  },
})

export const Trigger = styled(Tooltip.Trigger, {
  textDecoration: 'underline',
  padding: 0,
  background: 'none',
  cursor: 'pointer',
  border: 'none',
  fontFamily: 'Gilroy , sans-serif',
  variants: {
    noUnderline: {
      true: {
        textDecoration: 'none',
      },
    },
  },
})

export const Arrow = styled(Tooltip.Arrow, {
  fill: '#C5EDE6',
  borderLeft: '5px solid transparent',
  borderRight: '30px solid transparent',
  borderTop: '13px solid #ECF9F7',
  transform: 'rotate(55deg)',
})

export const Content = styled(Tooltip.Content, {
  backgroundColor: '#ECF9F7',
  zIndex: 1000000,
  width: '311px',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
  borderRadius: '31px',
  padding: '20px',
  textAlign: 'left',
  color: 'black',
  fontSize: '12px',
  fontWeight: 200,
  margin: '0 15px',
  p: {
    fontSize: '14px',
    margin: 0,
  },
  li: {
    fontSize: '14px',
    fontWeight: 200,
  },
  '.title': {
    fontSize: '13px',
    fontWeight: '600',
  },
  variants: {
    textMargin: {
      true: {
        p: {
          margin: '8px 0',
        },
      },
    },
  },
})
