import useApi from 'helpers/api'

const getPrice = async (moveServiceType: string, orderData: PriceRequestPayload) => {
  try {
    const api = useApi()
    const response = await api.fetch<PriceDetails>(`/widget/order/prices/${moveServiceType}`, 'POST', orderData)
    return response
  } catch (e) {
    console.log(e)
  }
}
export default getPrice
