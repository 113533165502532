import { STORAGE, PACKING, HANDYMAN } from '@/constants/AddonConstants'
import { AddonData } from '@/types/AdditionalServices'
import { TFunction } from 'next-i18next'

export const getAddonTypes = (t: TFunction) => {
  const addonTypes: AddonData[] = [
    {
      id: 0,
      name: STORAGE,
      title: t('addon:storageTitle'),
      description: t('addon:storageDescription'),
      information: t<string>('addon:storageInformation'),
    },
    {
      id: 1,
      name: PACKING,
      title: t('addon:packingTitle'),
      description: t('addon:packingDescription'),
      information: t<string>('addon:packingInformation'),
    },
    {
      id: 2,
      name: HANDYMAN,
      title: t('addon:handymanTitle'),
      description: t('addon:handymanDescription'),
      information: t<string>('addon:handymanInformation'),
    },
  ]
  return addonTypes
}
