import { styled } from 'stitches.config'

export const ContentWrapper = styled('div', {
  height: '100%',
  width: '100%',
  margin: '90px 0 0',
  maxWidth: '927px',
  padding: '0 20px',
})

export const OrderWidgetWrapper = styled('div', {
  margin: '0px 15px',
  width: '100%',
})

export const Stepper = styled('div', {
  position: 'fixed',
  top: '48px',
  height: '6px',
  backgroundColor: '$ghostBackground',
  transition: 'width 500ms',
  marginTop: '15px',
  zIndex: 1000,
})

export const StyledButton = styled('div', {
  width: '276px',
  height: '54px',
  borderRadius: '31px',
  marginBottom: '10px',
  '@sm': {
    width: '276px',
    height: '48px',
    borderRadius: '31px',
    marginBottom: '10px',
  },
})

export const CalendarWrapper = styled('div', {
  width: '100%',
})

export const ContinueButtonWrapper = styled('div', {
  marginTop: '16px',
})

export const SpinnerWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  '> div': {
    width: 'auto',
  },
})
