import { styled } from 'stitches.config'

export const SelectionWrapper = styled('div', {
  width: '100%',
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
})

export const SelectionButtonWrapper = styled('div', {
  width: '90%',
  height: '34px',
  borderRadius: '$radio',
  fontSize: '$5',
  fontWeight: 500,
  position: 'relative',
  background: '$gray',
  color: '#000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  variants: {
    selected: {
      true: {
        background: '$partnerBackground',
        color: '$whiteMain',
      },
    },
  },
  '&:hover': {
    cursor: 'pointer',
  },
})
export const ButtonsWrapper = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '5px',
})
