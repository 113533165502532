import { styled } from 'stitches.config'

export const Icon = styled('div', {
  position: 'absolute',
  top: 12,
  left: 8,
})

export const Label = styled('label', {
  position: 'absolute',
  left: '11px',
  top: '12.5px',
  color: '$inactiveDark',
  fontWeight: '$normal',
  fontSize: '1rem',
  pointerEvents: 'none',
  display: 'flex',
  transition: '0.2s ease',
  '&:before': {
    transition: 'all 0.2s ease',
    position: 'absolute',
    height: '50%',
    width: '100%',
    content: '',
  },
  '&:after': {
    transition: 'all 0.2s ease',
    position: 'absolute',
    height: '50%',
    width: '100%',
    content: '',
    top: '50%',
  },
})

export const ErrorText = styled('div', {
  color: '$errorRed',
  position: 'absolute',
  fontSize: '12px',
  top: 48,
  right: 1,
})

export const InputStyled = styled('input', {
  height: '47px',
  width: ' 100%',
  padding: '14px 12px',
  outline: 'none',
  borderRadius: '$input',
  border: '1.9px solid rgba(118, 118, 118, 0.4)',
  fontWeight: '$normal',
  fontSize: '16px',
  transition: 'border 10ms',
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: '0',
  },
  color: 'rgba(0, 0, 0, 0.7)',
  '&:focus': {
    border: '2px solid $tertiaryMain',
    transition: 'border 10ms',
  },
  '&:focus + .label': {
    top: '-5px',
    fontSize: '12px',
    backgroundColor: '#fff',
    padding: '0 2px',
    color: '$tertiaryMain',
    left: '10px',
    paddingLeft: '0px',
  },
  '&.has-value': {
    border: '1.9px solid rgba(118, 118, 118, 0.4)',
    transition: 'border 10ms',
    '+ .label': {
      top: '-5px',
      fontSize: '12px',
      backgroundColor: '#fff',
      padding: '0 2px',
      color: 'rgba(0, 0, 0, 0.7)',
      left: '10px',
    },
  },
  variants: {
    frontIcon: {
      true: {
        '+ .label': {
          top: '-9px',
          fontSize: '12px',
          backgroundColor: '#fff',
          padding: '0 2px',
          color: '$inactiveDark',
          left: '10px',
          paddingLeft: '0px',
        },
      },
    },
    error: {
      true: {
        border: '1.9px solid $errorRed',
        transition: 'border 10ms',
        '+ .label': {
          top: '-5px',
          fontSize: '12px',
          backgroundColor: 'white',
          padding: '0 2px',
          color: '$errorRed',
          left: ' 10px',
        },
      },
    },
  },
})

export const InputWrapper = styled('div', {
  width: '100%',
  maxWidth: '100%',
  position: 'relative',
  boxSizing: 'border-box',
  height: '100%',
})
