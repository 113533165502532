import * as React from 'react'
import { PlusIconWrapper, ServiceIconWrapper, OptionCardWrapper, TrailingText, LeadingText, MainContainer, ChipWrapper, ClickContainer } from './OptionCard.styles'
import Chip from '@/components/atoms/Chip'
import { useTranslation } from 'react-i18next'
import Checkbox from '@/components/atoms/Checkbox/Checkbox'

export type OptionCardProps = {
  title: string
  price?: string
  isServiceSelected?: boolean
  plusIcon: React.ReactNode
  serviceIcon: React.ReactNode
  onclick: React.MouseEventHandler
  color?: 'lightblue' | 'lightgreen' | 'lightyellow' | 'lightgrey'
  borderColor?: 'blue' | 'green' | 'yellow' | 'grey'
  type?: string
  unSelect: React.MouseEventHandler
}
export const OptionCard = ({ title, plusIcon, isServiceSelected, serviceIcon, onclick, color = 'lightblue', price, borderColor, unSelect }: OptionCardProps) => {
  const { t } = useTranslation(['address', 'common'])

  return isServiceSelected ? (
    <OptionCardWrapper color={color} borderColor={borderColor}>
      {price && isServiceSelected && (
        <ChipWrapper>
          <Chip text={price ?? ''} variant="green" />
        </ChipWrapper>
      )}
      <MainContainer color={color}>
        <ServiceIconWrapper> {serviceIcon} </ServiceIconWrapper>
        <LeadingText>{title}</LeadingText>
      </MainContainer>
      <MainContainer>
        <Checkbox checked onClick={unSelect} />
      </MainContainer>
    </OptionCardWrapper>
  ) : (
    <ClickContainer onClick={onclick}>
      <OptionCardWrapper color={color} borderColor={borderColor}>
        {price && (
          <ChipWrapper>
            <Chip text={price ?? ''} variant="green" />
          </ChipWrapper>
        )}

        <MainContainer color={color}>
          <ServiceIconWrapper> {serviceIcon} </ServiceIconWrapper>
          <LeadingText>{title}</LeadingText>
        </MainContainer>
        <MainContainer>
          <PlusIconWrapper>{plusIcon}</PlusIconWrapper>
          <TrailingText>{t('common:read')}</TrailingText>
        </MainContainer>
      </OptionCardWrapper>
    </ClickContainer>
  )
}
