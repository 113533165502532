import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { mergeRefs } from '@/common/helpers/mergeRefs'
import { InputWrapper, InputStyled, Label, Icon, ErrorText } from './Input.styles'

type InputProps = {
  label?: string
  type: 'text' | 'number'
  error?: string
  withCursorPositionUpdate?: boolean
  frontIcon?: React.ReactNode
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ label, type, error, frontIcon, onChange, withCursorPositionUpdate = false, ...props }, forwardRef) => {
  const { value, className } = props
  const [cursor, setCursor] = useState<number | null>(null)
  const ref = useRef<HTMLInputElement>()
  const mergedRef = mergeRefs([ref, forwardRef])

  useEffect(() => {
    const fn = (e: Event) => {
      const target = e.target as HTMLInputElement
      if (target?.value) {
        target.classList.add('has-value')
      } else {
        target.classList.remove('has-value')
      }
    }
    const refCurrent = ref.current?.addEventListener('change', fn)
    return () => refCurrent
  }, [ref])

  useEffect(() => {
    if (withCursorPositionUpdate) {
      const input = ref.current
      if (input) input.setSelectionRange(cursor, cursor)
    }
  }, [ref, cursor, value, withCursorPositionUpdate])
  return (
    <InputWrapper>
      {frontIcon && <Icon>{frontIcon}</Icon>}
      <InputStyled
        error={!!error}
        ref={mergedRef}
        type={type}
        onChange={(e) => {
          setCursor(e.target.selectionStart)
          if (onChange) onChange(e)
        }}
        {...props}
        onWheel={(e) => type === 'number' && e.currentTarget.blur()}
        className={classNames(className, value || props.defaultValue !== undefined ? 'has-value' : null)}
        frontIcon={frontIcon !== undefined ? true : false}
      />
      <Label className="label">
        <span>{label}</span>
      </Label>
      {error && <ErrorText>{error}</ErrorText>}
    </InputWrapper>
  )
})

export default Input
