import React, { useState, useCallback } from 'react'
import { useFormContext, useController } from 'react-hook-form'
import { useDebounce } from 'react-use'
import { useTranslation } from 'react-i18next'
import Input from '@/components/atoms/Input'
import { CommonAddressWrapper, ZipCode, AutoCompleteWrapper, FlexRow, AutoCompleteStyled, CityWrapper } from './CommonAddressForm.styles'
import { useCurrentStepContext } from '@/common/context/steps/CurrentStepProvider'

interface CommonAddressFormInputs {
  autoCompleteAddress: string
  street: string
  streetNumber: string
  zip: string
  city: string
}

interface CommonAddressFormProps {
  frontIcon?: React.ReactNode
}

const CommonAddressForm = ({ frontIcon }: CommonAddressFormProps) => {
  const { t } = useTranslation(['address', 'errors'])
  const { currentStep } = useCurrentStepContext()
  const {
    setValue,
    control,
    register,
    getValues,
    formState: { errors },
  } = useFormContext<CommonAddressFormInputs>()
  const {
    field: { onChange, name, value, ref },
  } = useController({
    name: 'autoCompleteAddress',
    control,
  })
  const [customInput, setCustomInput] = useState(false)
  const [googleResult, setGoogleResult] = useState<google.maps.places.AutocompletePrediction[]>([])
  const [val, setVal] = useState('')

  useDebounce(
    () => {
      if (typeof window !== undefined) {
        const autocompleteService = new window.google.maps.places.AutocompleteService()
        autocompleteService.getPlacePredictions(
          {
            input: val,
            componentRestrictions: { country: 'se' },
          },
          (result) => {
            setGoogleResult(result || [])
          }
        )
      }
    },
    200,
    [val, setGoogleResult]
  )

  const customOnChange = useCallback(
    (onChangeValue: string | undefined) => {
      if (onChangeValue) setVal(onChangeValue)
    },
    [setVal]
  )

  const onSelect = useCallback(
    (addressValue: string) => {
      const resetValues = () => {
        setValue('autoCompleteAddress', '')
        setValue('street', '')
        setValue('streetNumber', '')
        setValue('zip', '')
        setValue('city', '')
      }
      if (addressValue === 'NoAddress') {
        setCustomInput(true)
        resetValues()
        setValue('autoCompleteAddress', 'NoAddress')
      } else {
        const detailsService = new google.maps.places.PlacesService(document.createElement('div'))
        const request = {
          placeId: addressValue,
          fields: ['name', 'address_component'],
        }

        detailsService.getDetails(request, (place) => {
          if (!!place?.address_components?.length) {
            const address = place?.address_components
            const streetNumber = address.find((component) => component.types.includes('street_number'))?.long_name

            setValue('street', address.find((component) => component.types.includes('route'))?.long_name || '')
            /**
             * Entering an address with a streeetnumber ending with a letter (57B etc) will present a option but this may not exist.
             * For example you can enter Gatan 3B and choose it but if that street doesn't exist street_number will only contain 3.
             */
            setValue('streetNumber', streetNumber || '')
            setValue('zip', address.find((component) => component.types.includes('postal_code'))?.long_name.replace(' ', '') || '')
            setValue(
              'city',
              address.find((component) => component.types.includes('postal_town'))?.long_name ||
                address.find((component) => component.types.includes('sublocality'))?.long_name ||
                ''
            )
          }
        })
      }
    },
    [setValue]
  )

  return (
    <CommonAddressWrapper>
      {!customInput && getValues().autoCompleteAddress !== t('noAddressText') ? (
        <>
          <FlexRow>
            <AutoCompleteWrapper>
              <AutoCompleteStyled
                onChange={(event) => {
                  onChange(event) // data send back to hook form
                  customOnChange(event) // UI state
                }}
                items={[...googleResult.map((loc) => ({ label: loc.description, value: loc.place_id })), { label: t('noAddressText'), value: 'NoAddress' }]}
                label={t('address')}
                placeholder={currentStep === 3 ? t<string>('NEWADDRESS.enterNewAddress') : t<string>('CURRENTADDRESS.enterCurrentAddress')}
                onSelect={onSelect}
                name={name}
                ref={ref}
                value={value}
                frontIcon={frontIcon}
                error={errors?.autoCompleteAddress?.message || (errors?.zip && t<string>('errors:fullAddressRequired'))}
              />
            </AutoCompleteWrapper>
          </FlexRow>
        </>
      ) : (
        <>
          <FlexRow>
            <AutoCompleteWrapper>
              <Input defaultValue={getValues().street} type="text" label={t('street').toString()} {...register('street')} error={errors?.street?.message} />
            </AutoCompleteWrapper>
          </FlexRow>
          <FlexRow>
            <ZipCode>
              <Input defaultValue={getValues().zip} type="text" label={t('zipCode').toString()} {...register('zip')} error={errors?.zip?.message} />
            </ZipCode>
            <CityWrapper>
              <Input defaultValue={getValues().city} type="text" label={t('city').toString()} {...register('city')} error={errors?.city?.message} />
            </CityWrapper>
          </FlexRow>
        </>
      )}
    </CommonAddressWrapper>
  )
}

export default CommonAddressForm
