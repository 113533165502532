import useApi from 'helpers/api'

const createOrder = async (order: OrderPayload) => {
  try {
    const api = useApi()
    const response = await api.fetch<OrderResponse>(`/widget/order/create`, 'POST', order)
    return response
  } catch (e) {
    console.log(e)
  }
}
export default createOrder
