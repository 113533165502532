import { styled } from 'stitches.config'

export const DayPickerWrapper = styled('div', {
  background: '#FAFAFC',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 2,
  borderRadius: '17.5673px',
  margin: '14px 0px 35px 0px',
  marginBottom: '35px',
  border: '1.46394px solid #EEEEF0',
  //boxShadow: '0px 0px 15px 5px lightgrey',
  boxShadow: '0px 11.7115px 32.2067px rgba(0, 0, 0, 0.06)',
})

export const ModifiersStyles = {
  today: {
    color: '#4C96D0',
  },
  sundays: {
    color: '#F25A43',
  },
}

export const Wrapper = styled('div', {
  '.DayPicker': {
    minWidth: '280px',
    '&-Caption': {
      '> div': {
        color: 'black',
        fontWeight: 400,
        textAlign: 'center',
      },
    },
    '&-NavButton--prev': {
      marginRight: '16.5em',
    },
    '&-Day': {
      fontSize: '$7',
      color: 'black',
      fontWeight: '$extraSmall',
      width: '40px',
      height: '40px',
      padding: 0,

      '&--past': {
        color: '#b7b7b7',
        borderRadius: 0,
        '&:hover': {
          background: 'white !important',
          backgroundColor: 'white !important',
          cursor: 'unset',
        },
      },
      '&--disabled': {
        borderRadius: 0,
        color: 'rgba(0, 0, 0, 0.25) !important',
      },
      '&--selected': {
        background: '$secondaryMain',
        color: '$whiteMain !important',
        '&:hover': {
          backgroundColor: '$secondaryMain',
        },
      },
    },
    '&-Weekday, &-WeekNumber': {
      fontWeight: '$normal',
      fontSize: '$4',
      borderRight: 'unset',
    },
    '&-Weekday': {
      padding: '1.0em 0.5em',
    },
    '&-WeekdaysRow': {
      color: '$blackMain',
      fontWeight: 100,
    },
  },
  '.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
    background: '$secondaryMain',
    fontWeight: 400,
    color: '$whiteMain',
    '&:hover': {
      backgroundColor: '$secondaryMain',
    },
  },
})
