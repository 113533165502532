import { styled } from 'stitches.config'

export const Wrapper = styled('div', {
  position: 'relative',
})

export const ComboboxStyles = styled('div', {})

export const MenuStyles = styled('ul', {
  listStyle: 'none',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.08)',
  borderRadius: '8',
  padding: 0,
  margin: '1px 0',
  li: {
    padding: '8px 16px',
  },
  zIndex: 5000,
  background: '$whiteMain',
  position: 'absolute',
  width: '100%',
  borderBottomRightRadius: '$borderRadiusMain',
  borderBottomLeftRadius: '$borderRadiusMain',
})

export const ItemStyle = styled('li', {
  textAlign: 'left',
  '&:last-of-type': {
    color: '$errorRed',
    textDecoration: 'underline',
    borderBottomRightRadius: '$borderRadiusMain',
    borderBottomLeftRadius: '$borderRadiusMain',
    paddingBottom: '16px',
  },
  variants: {
    active: {
      true: {
        backgroundColor: '$tertiaryVeryLight',
      },
    },
    noHits: {
      true: {
        textAlign: 'center',
        color: '$errorRed',
      },
    },
  },
  '&:hover': {
    cursor: 'pointer',
  },
})

export const Icon = styled('div', {
  position: 'absolute',
  top: 6,
  left: 8,
})
