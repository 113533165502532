import React from 'react'
import SpinnerImage from '@/public/images/Spinner.svg'
import { SvgWrapper, StyledSpinner } from './Spinner.styles'

export interface SpinnerProps {
  scale?: 1 | 1.5 | 2
}

const Spinner = ({ scale }: SpinnerProps) => {
  return (
    <SvgWrapper scale={scale} data-testid="spinner-wrapper">
      <StyledSpinner data-testid="spinner">
        <SpinnerImage />
      </StyledSpinner>
    </SvgWrapper>
  )
}
export default Spinner
