import React from 'react'
import { useTranslation, Trans } from 'next-i18next'
import { MainContainer, SubContainer } from './SelectMoveDate.styles'
import Calendar from '@/components/organisms/Calendar'
import { useCurrentStepContext } from '@/common/context/steps/CurrentStepProvider'
import ArrowRight from '@/public/images/ArrowRight.svg'
import { StyledButton } from '../../OrderWidgetTemplate.styles'
import Button from '@/components/atoms/Button'
import Flex from '@/components/atoms/Flex'
import ActivityEnum from '@/common/enums/ActivityEnum'
import { useRouter } from 'next/router'

export interface SelectMoveDateProps {
  movingDate?: Date
}

const SelectMoveDate = ({ movingDate }: SelectMoveDateProps) => {
  const { t } = useTranslation(['dateNames', 'common', 'address'])
  const { currentStep, incrementStep } = useCurrentStepContext()
  const router = useRouter()
  const { type } = router.query
  const updateStepCount = () => {
    incrementStep()
  }

  return (
    <>
      <MainContainer>
        <SubContainer className="left">
          <div className="infoTextContainer">
            <p className="titleText">{t('common:desiredDate')}</p>
          </div>
          <div className="infoTextContainer">
            <p className="infotext1">
              <Trans i18nKey="common:infoText" components={{ 1: <br /> }} />
            </p>
          </div>
          <div className="infoTextContainer">
            <p className="infotext2">
              <Trans i18nKey="common:infoText2" components={{ 1: <br /> }} />
            </p>
          </div>
        </SubContainer>
        <SubContainer>
          <Calendar movingDate={movingDate} />
          <Flex direction={'row'} justifyContent={'center'}>
            <StyledButton>
              <Button
                onClick={updateStepCount}
                text={t('common:continue')}
                variant="primaryAlt"
                iconRight={<ArrowRight />}
                disabled={!movingDate && currentStep === 1 ? true : false}
              />
            </StyledButton>
          </Flex>
          <Flex direction={'row'} justifyContent={'center'} alignItems={'center'}>
            <p>
              <strong>{`${t('dateNames:next_step')}: `}</strong>
              {type === ActivityEnum.MOVEHELP ? t('dateNames:current_address') : t('address:NEWADDRESS.yourAddress')}
            </p>
          </Flex>
        </SubContainer>
      </MainContainer>
    </>
  )
}

export default SelectMoveDate
