import { styled } from 'stitches.config'

export const ErrorPageWrapper = styled('div', {
  width: '100%',
  textAlign: 'center',
  color: '$secondaryMain',
  '.textWrapper': {
    marginTop: '20px',
  },
  '.inputContainer':{
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center'
  }
})

export const InputWrapper = styled('div', {
  '@sm': {
    width: '311px',
    height: '46px',
    margin: '30px 10px',
    textAlign: 'left',
    borderRadius: '8px',
    padding: '8px, 12px, 8px, 12px',
  },
  width: '311px',
  height: '46px',
  margin: '30px 10px',
  textAlign: 'left',
  borderRadius: '8px',
  padding: '8px, 12px, 8px, 12px',
})

export const StyledButton = styled('div', {
  height: '48px',
  width: '311px',
  margin: '40px 10px',
  '@sm': {
    height: '48px',
    width: '309px',
    margin: '40px 10px',
  },
})
