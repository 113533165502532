import H3 from '@/components/atoms/H3'
import { AccordionItem } from '@/components/molecules/Accordion'
import { styled } from 'stitches.config'

export const PriceViewWrapper = styled('div', {
  width: '100%',
  textAlign: 'left',
  color: '$secondaryMain',
  H3: {
    margin: 0,
  },
  '.continueBtn': {
    marginTop: '30px',
  },
  '@sm': {
    '.additionalservices': {
      marginTop: '20px',
    },
    '.continueBtn': {
      marginTop: '30px',
    },
  },
})

export const PriceDetails = styled('div', {
  background: 'white',
  boxShadow: '0px 8px 22px 0px #0000000F',
  borderRadius: '$borderRadiusMain',
  textAlign: 'center',
  paddingBottom: '10px',
  paddingTop: '30px',
  '@sm': {
    width: '660px',
    background: 'white',
    boxShadow: 'none',
    borderRadius: '$borderRadiusMain',
    textAlign: 'center',
    paddingBottom: '10px',
    maxWidth: '100%',
    margin: '0 auto',
  },
})
export const StyledButton = styled('div', {
  width: '276px',
  height: '54px',
  borderRadius: '31px',
  marginBottom: '10px',
  '@sm': {
    width: '276px',
    height: '48px',
    borderRadius: '31px',
    marginBottom: '10px',
  },
})

export const PriceMainText = styled(H3, {
  paddingTop: '15px',
  paddingBottom: '10px',
})

export const PriceMainTextLabel = styled('span', {
  fontSize: '$4',
  fontWeight: '700',
  display: 'block',
  '@sm': {
    fontSize: '36px',
    display: 'inline',
  },
})

export const PriceText = styled('label', {
  color: '#71B6ED',
  fontWeight: '700',
  fontSize: '41px',
  marginLeft: '10px',
})

export const AccordionItemView = styled('div', {
  marginTop: '20px',
})

export const AccordionItemWrapper = styled('div', {
  marginTop: '10px',
  boxShadow: '0px 8px 22px 0px #0000000F',
  borderRadius: '$borderRadiusMain',
})

export const AccordionItemHeading = styled('p', {
  fontWeight: '700',
})

export const ChangeDetailsText = styled('p', {
  padding: '15px',
  fontWeight: '700',
})

export const AccordionContentWrapper = styled('div', {
  color: '$secondaryMain',
  textAlign: 'center',
})

export const StyledAccordionItem = styled(AccordionItem, {
  borderRadius: '$borderRadiusMain',
})

export const AccordionButtonWrapper = styled('div', {
  button: {
    backgroundColor: '$whiteMain',
    padding: '0 20px 0 0',
    '&[data-state="open"]': { backgroundColor: '$whiteMain' },
    '&[data-state="closed"]': { backgroundColor: '$whiteMain' },
  },
})

export const IconWrapper = styled('div', {
  margin: '25px',
  width: '50px',
})

export const TextWrapper = styled('div', {
  width: '100%',
  textAlign: 'left',
  margin: '30px 60px 30px 0px',
  color: '$inactiveSuperDark',
  H3: {
    fontSize: '$5',
    fontWeight: 500,
    marginBottom: '4px',
  },
  p: {
    fontSize: '$3',
  },
})

export const DottedLine = styled('div', {
  borderTop: '1px dashed #E1E1E3',
})

export const DetailsWrapper = styled('div', {
  display: 'flex',
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '$whiteMain',
  padding: '20px',
  height: '40px',
  H3: {
    fontSize: '$5',
    fontWeight: 700,
    '@sm': {
      fontSize: '$6',
    },
  },
  p: {
    color: 'black',
    fontSize: '$5',
    '@sm': {
      fontSize: '$6',
    },
  },
  variants: {
    extraHorizontalPadding: {
      true: {
        paddingLeft: '30px',
        paddingRight: '30px',
      },
    },
  },
})

export const AdditionalServiceWrapper = styled('div', {
  marginTop: '30px',
  '.additionalServiceHeading': {
    fontWeight: '700',
    marginBottom: '$1',
  },
})

export const AddonsTitle = styled('p', {
  fontWeight: '700',
  textAlign: 'left',
  paddingLeft: '30px',
  marginTop: '30px',
  fontSize: '$5',
  '@sm': {
    textAlign: 'center',
    paddingLeft: '0',
    fontSize: '$6',
  },
})
