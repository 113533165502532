import React, { useCallback, useMemo, useEffect } from 'react'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { AddressWrapper, InputWrapper, MainContainer, SelectionWrapper, SubContainer, SizeOfAdditionalSpaceText } from './CurrentAddress.styles'
import { APARTMENT, NO_ELEVATOR, NO, YES } from '@/constants/addressFormConstants'
import ActivityEnum from '@/common/enums/ActivityEnum'
import { getElevatorTypes, getHeavyLiftingTypes, getLimitedAccessibilityTypes, getResidenceTypes } from '@/common/helpers/SelectionTypes'
import Flex from '@/components/atoms/Flex'
import H2 from '@/components/atoms/H2'
import Selection from '@/components/molecules/Selection'
import Input from '@/components/atoms/Input/Input'
import CommonAddressForm from '@/components/organisms/CommonAddressForm'
import TextArea from '@/components/atoms/TextArea'
import MapPin from '@/public/images/MapPin.svg'
import MyHouse from '@/public/images/Delivery.svg'
import H3 from '@/components/atoms/H3'
import { useCurrentStepContext } from '@/common/context/steps/CurrentStepProvider'
import { StyledButton } from '../../OrderWidgetTemplate.styles'
import Button from '@/components/atoms/Button'
import { useMoveDateContext } from '@/common/context/moveDate/MoveDateProvider'
import ArrowRight from '@/public/images/ArrowRight.svg'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useResponsive from '@/constants/hooks/useResponsive'
import MoveClean from '@/public/images/Moveclean.svg'
import MovingFromIcon from '@/public/images/MovingFromIcon.svg'

import { useFormAddressContext } from '@/common/context/addressForm/AddressFormProvider'
export const currentAddressFormDefaultValues = {
  autoCompleteAddress: '',
  residenceType: APARTMENT,
  elevatorSize: NO_ELEVATOR,
  limitedAccessibility: NO,
  heavyLifting: NO,
  //doorCode: '',
}
interface CurrentAddressProps {
  type: string
}

const CurrentAddress = ({ type }: CurrentAddressProps) => {
  const { t } = useTranslation(['address', 'common', 'errors'])
  const methods = useFormContext<AddressInfo>()
  const {
    watch,
    setValue,
    register,
    getValues,
    formState: { errors },
  } = methods
  const residenceTypeData = useMemo(() => getResidenceTypes(t), [t('apartment')])
  const elevatorTypeData = useMemo(() => getElevatorTypes(t), [t('noElevator')])
  const limitedAccessibilityTypeData = useMemo(() => getLimitedAccessibilityTypes(t), [t('common:no')])
  const heavyLiftingTypeData = useMemo(() => getHeavyLiftingTypes(t), [t('common:no')])
  const { currentStep, incrementStep } = useCurrentStepContext()
  const { updateCurrentAddress } = useFormAddressContext()
  const { movingDate } = useMoveDateContext()
  const { isTabletPortraitOrGreater } = useResponsive()

  const onCurrentAddressSubmit = methods.handleSubmit(async (data: AddressInfo) => {
    updateCurrentAddress(data)
    incrementStep()
  })

  const onSelectResidenceType = useCallback(
    (value: 'apartment' | 'townhouse' | 'house' | string) => {
      setValue('residenceType', value)
      //setValue('doorCode', currentAddressFormDefaultValues.doorCode)
    },
    [watch('residenceType')]
  )

  const onSelectElevatorType = useCallback(
    (value: 'large' | 'small' | 'none' | string) => {
      setValue('elevatorSize', value)
    },
    [watch('elevatorSize')]
  )

  const onSelectLimitedAccessibility = useCallback(
    (value: string) => {
      setValue('limitedAccessibility', value)
    },
    [watch('limitedAccessibility')]
  )

  const onSelectHeavyLifting = useCallback(
    (value: string) => {
      setValue('heavyLifting', value)
    },
    [watch('heavyLifting')]
  )

  return (
    <AddressWrapper>
      <MainContainer>
        <SubContainer>
          <Flex
            justifyContent={isTabletPortraitOrGreater ? 'start' : 'center'}
            alignItems={isTabletPortraitOrGreater ? 'end' : 'center'}
            direction={isTabletPortraitOrGreater ? 'row' : 'column'}
          >
            <div className="iconContainer">
              <MovingFromIcon />
            </div>
            <div className="titleTextContainer">
              <p className="titleText">{t('CURRENTADDRESS.currentResidence')}</p>
            </div>
          </Flex>
          <FormProvider {...methods}>
            <CommonAddressForm frontIcon={<MapPin />} />
          </FormProvider>
          <div className="inputWrapper">
            <Selection data={residenceTypeData} onSelectValue={onSelectResidenceType} selectedValue={watch('residenceType')} />
          </div>
          <InputWrapper className="inputWrapper">
            <Flex direction="row" justifyContent="space-between" alignItems="center">
              <H3 className="noMarginBottom">{t('residenceSize')}</H3>
              <div className="smallerInput">
                <Input
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  label={t<string>('address:sqm')}
                  {...register('residenceSize', { valueAsNumber: true })}
                  error={errors.residenceSize?.message}
                  defaultValue={isNaN(getValues().residenceSize) ? undefined : getValues().residenceSize}
                />
              </div>
            </Flex>
          </InputWrapper>
          <InputWrapper className="inputWrapper">
            <Flex direction="row" justifyContent="space-between" alignItems="center">
              <Flex direction="column" justifyContent="start" alignItems="center">
                <SizeOfAdditionalSpaceText>
                  <H3>{t('sizeOfAdditionalSpace')}</H3>
                  <p>{t('sizeOfAdditionalSpaceExample')}</p>
                </SizeOfAdditionalSpaceText>
              </Flex>
              <div className="smallerInput">
                <Input
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  label={t<string>('address:sqm')}
                  {...register('sizeOfAdditionalSpace', { valueAsNumber: true })}
                  error={errors.sizeOfAdditionalSpace?.message}
                  defaultValue={isNaN(getValues().sizeOfAdditionalSpace) ? undefined : getValues().sizeOfAdditionalSpace}
                />
              </div>
            </Flex>
          </InputWrapper>

          {watch('residenceType') === APARTMENT && (
            <>
              <InputWrapper className="inputWrapper">
                <Flex direction="row" justifyContent="space-between" alignItems="center">
                  <H3 className="noMarginBottom">{t('floor')}</H3>
                  <div className="smallerInput">
                    <Input
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      {...register('floor', { valueAsNumber: true })}
                      error={errors.floor?.message}
                      defaultValue={isNaN(getValues().floor) ? undefined : getValues().floor}
                    />
                  </div>
                </Flex>
              </InputWrapper>

              {/* <InputWrapper className="inputWrapper">
                <Flex direction="row" justifyContent="space-between" alignItems="center">
                  <H3 className="noMarginBottom">
                    {t('doorCode')}/{t('intercom')}
                  </H3>
                  <div className="doorCodeInput">
                    <Input type="text" {...register('doorCode')} error={errors.doorCode?.message} defaultValue={getValues().doorCode} />
                  </div>
                </Flex>
              </InputWrapper> */}
            </>
          )}
        </SubContainer>
        <SubContainer side="right">
          {watch('residenceType') === APARTMENT && (
            <SelectionWrapper>
              <InputWrapper className="inputWrapper">
                <Flex direction="column" justifyContent="space-between" alignItems="start">
                  <H3 className="noMarginBottom">{t('isElevator')}</H3>
                  <p style={{ marginTop: 2, marginBottom: 0 }}>{t('isElevatorText')}</p>
                </Flex>
              </InputWrapper>
              <InputWrapper className="inputWrapper">
                <Selection data={elevatorTypeData} onSelectValue={onSelectElevatorType} selectedValue={watch('elevatorSize')} />
              </InputWrapper>
            </SelectionWrapper>
          )}
          {type !== ActivityEnum.MOVECLEAN && (
            <>
              <InputWrapper className="inputWrapper">
                <Flex direction="column" justifyContent="space-between" alignItems="start">
                  <H3 className="noMarginBottom">{t('heavyLifting')}</H3>
                  <p style={{ marginTop: 2, marginBottom: 0 }}>{t('heavyLiftingText')}</p>
                </Flex>
              </InputWrapper>
              <Selection data={heavyLiftingTypeData} onSelectValue={onSelectHeavyLifting} selectedValue={watch('heavyLifting') || NO} />
            </>
          )}
          <>
            <InputWrapper className="inputWrapper">
              <Flex direction="column" justifyContent="space-between" alignItems="start">
                <H3 className="noMarginBottom">{t('limitedAccessibility')}</H3>
                <p style={{ marginTop: 2, marginBottom: 0 }}>{t('limitedAccessibilityText')}</p>
              </Flex>
            </InputWrapper>
            <Selection data={limitedAccessibilityTypeData} onSelectValue={onSelectLimitedAccessibility} selectedValue={watch('limitedAccessibility')} />
          </>
          {(watch('limitedAccessibility') === YES || watch('heavyLifting') === YES) && (
            <InputWrapper className="inputWrapper">
              <Flex direction="column" justifyContent="space-between" alignItems="center">
                <TextArea {...register('limitedAccessibilityCustomerNotes')} label={t<string>('limitedAccessibilityCustomerNoteLabel')} />
              </Flex>
            </InputWrapper>
          )}
          <Flex direction={'row'} justifyContent={'center'} className="continueBtn">
            <StyledButton>
              <Button
                onClick={onCurrentAddressSubmit}
                text={t('common:continue')}
                variant="primaryAlt"
                iconRight={<ArrowRight />}
                disabled={!movingDate && currentStep === 1 ? true : false}
              />
            </StyledButton>
          </Flex>
          <Flex direction={'row'} justifyContent={'center'} alignItems={'center'}>
            <p>
              <strong>{`${t('dateNames:next_step')}: `}</strong>
              {t('address:NEWADDRESS.newAddress')}
            </p>
          </Flex>
        </SubContainer>
      </MainContainer>
    </AddressWrapper>
  )
}

export default CurrentAddress
