import React from 'react'
import { Input, Label, Error } from './Checkbox.style'

// Based on https://moderncss.dev/pure-css-custom-checkbox-style/

type CheckboxProps = {
  label?: React.ReactNode
  error?: string
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(({ label, error, ...props }, ref) => {
  const { disabled } = props

  return (
    <div>
      <Label className={disabled ? 'disabled' : ''}>
        <Input ref={ref} type="checkbox" {...props} />
        {label}
      </Label>
      {error && <Error>{error}</Error>}
    </div>
  )
})

export default Checkbox
