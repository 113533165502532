import useApi from 'helpers/api'

const validateOrganization = async (organizationId: string) => {
  try {
    const api = useApi()
    const response = await api.fetch<OrganizationResponse>(`/widget/auth/validate/${organizationId}`, 'GET')
    return response
  } catch (e) {
    console.log(e)
  }
}
export default validateOrganization
