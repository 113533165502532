import Flex from '@/components/atoms/Flex'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { CalendarWrapper, ContentWrapper, OrderWidgetWrapper, SpinnerWrapper, Stepper } from './OrderWidgetTemplate.styles'
import SelectMoveDate from './Sections/SelectMoveDate'
import { useTranslation } from 'next-i18next'
import ActivityEnum from '@/common/enums/ActivityEnum'
import { useCurrentStepContext } from '@/common/context/steps/CurrentStepProvider'
import NewAddress, { newAddressDefaultValues } from './Sections/NewAddress/NewAddress'
import CurrentAddress from './Sections/CurrentAddress/CurrentAddress'
import { APARTMENT } from '@/constants/addressFormConstants'
import { useMoveDateContext } from '@/common/context/moveDate/MoveDateProvider'
import ContactDetails from './Sections/ContactDetails'
import Confirmation from './Sections/Confirmation'
import PriceView from './Sections/PriceView'
import { useRouter } from 'next/router'
import validateOrganization from '@/helpers/validateOrganization'
import { SUCCESS } from '@/constants/common'
import ErrorPage from './Sections/ErrorPage'
import Spinner from '@/components/atoms/Spinner/Spinner'
import va from '@vercel/analytics'
export interface OrderWidgetTemplateProps {
  activeStep?: number
  type: string
  isQuotation: boolean
}

export const OrderWidgetTemplate = ({ activeStep, type, isQuotation }: OrderWidgetTemplateProps) => {
  const { t } = useTranslation(['errors', 'common', 'addon'])
  const router = useRouter()
  const { organizationId } = router.query
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<ErrorObject>({
    isError: false,
    title: '',
    description: '',
    subTitle: '',
  })
  const [totalSteps, setTotalSteps] = useState(1)
  const { currentStep, updateCurrentStep, updateServiceType } = useCurrentStepContext()

  const { movingDate } = useMoveDateContext()
  const addressSchema = yup
    .object({
      autoCompleteAddress: yup.string().min(2, t<string>('invalidAddress')).required(t<string>('invalidAddress')),
      street: yup.string().required(t<string>('cantBeEmpty')),
      streetNumber: yup.string(),
      zip: yup.string().min(5, t<string>('invalidZip')).max(5, t<string>('invalidZip')).required(t<string>('invalidZip')),
      city: yup.string().required(t<string>('cantBeEmpty')),
      residenceSize: yup.number().typeError(t<string>('cantBeEmpty')).min(1, t<string>('cantBeZero')).required(t<string>('cantBeEmpty')),
      additionalSpace: yup.string(),
      sizeOfAdditionalSpace: yup
        .number()
        .transform((value: number) => (isNaN(value) ? undefined : value))
        .nullable(),
      apartmentType: yup.string(),
      elevatorType: yup.string(),
      //doorCode: yup.string(),
      floor: yup.number().when('residenceType', {
        is: APARTMENT,
        then: yup.number().typeError(t<string>('cantBeEmpty')).required(t<string>('cantBeEmpty')),
        // Is should allow you to progress if you try to validate when residencetype = apartment and then changes
        // the type to something else.
        otherwise: yup
          .number()
          .transform((value: number) => (isNaN(value) ? undefined : value))
          .nullable(),
      }),
      // Selection components, do they need error messages?
      limitedAccessibility: yup.string(),
      heavyLifting: yup.string(),
      residenceType: yup.string(),
    })
    .required()

  const currentFormMethods = useForm<AddressInfo>({ resolver: yupResolver(addressSchema), defaultValues: newAddressDefaultValues })
  const newAddressFormMethods = useForm<AddressInfo>({
    resolver: yupResolver(addressSchema),
    defaultValues: newAddressDefaultValues,
  })

  useEffect(() => {
    const validate = async () => {
      const organizationResponse = await validateOrganization(organizationId as string)
      if (organizationResponse?.status === SUCCESS) {
        setError({
          isError: false,
          title: '',
          description: '',
          subTitle: '',
        })
      } else {
        setError({
          isError: true,
          title: t('errors:callYou'),
          description: t('errors:callYouDescription'),
          subTitle: t<string>(`errors:MESSAGE_KEYS.${organizationResponse?.messageKey}`),
        })
      }
      setIsLoading(false)
    }
    if (organizationId && router.isReady) {
      validate()
    } else if (!organizationId && router.isReady) {
      setError({
        isError: true,
        title: t('errors:callYou'),
        description: t('errors:callYouDescription'),
        subTitle: t<string>('errors:MESSAGE_KEYS.ORGANIZATION_NOT_FOUND'),
      })
      setIsLoading(false)
    }
  }, [organizationId])

  useEffect(() => {
    updateServiceType(type)
    if (type === ActivityEnum.MOVEHELP || type === ActivityEnum.MOVEHELP_COMBINED) {
      setTotalSteps(6)
    } else {
      // If the widget wants to order type moveclean doesn't have the steps where you fill in NewAddress information so it's one step less.
      setTotalSteps(5)
    }

    if (activeStep) {
      updateCurrentStep(Number(activeStep))
    }
  }, [activeStep])

  useEffect(() => {
    va.track('FUNNEL', {
      product: type,
      flow: isQuotation ? 'quotation' : 'order',
      step:
        currentStep === 1
          ? 'select-date'
          : currentStep === 2
          ? 'current-address'
          : currentStep === 3 && type === ActivityEnum.MOVEHELP
          ? 'new-address'
          : currentStep === 4 && type === ActivityEnum.MOVEHELP
          ? 'price'
          : currentStep === 5 && type === ActivityEnum.MOVEHELP
          ? 'contact-details'
          : currentStep === 6 && type === ActivityEnum.MOVEHELP
          ? 'confirmation'
          : currentStep === 3 && type === ActivityEnum.MOVECLEAN
          ? 'price'
          : currentStep === 4 && type === ActivityEnum.MOVECLEAN
          ? 'contact-details'
          : currentStep === 5 && type === ActivityEnum.MOVECLEAN
          ? 'confirmation'
          : 'undefined',
    })
  }, [currentStep])

  return (
    <>
      <Stepper style={{ width: `${(currentStep / totalSteps) * 100}%` }} />
      <Flex direction="column" justifyContent="center" alignItems="center">
        <ContentWrapper>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <OrderWidgetWrapper>
              {isLoading ? (
                <SpinnerWrapper>
                  <Spinner scale={2} />
                </SpinnerWrapper>
              ) : !error.isError ? (
                <>
                  {currentStep === 1 && (
                    <CalendarWrapper>
                      <SelectMoveDate movingDate={movingDate} />
                    </CalendarWrapper>
                  )}

                  {currentStep === 2 && (
                    <FormProvider {...currentFormMethods}>
                      <CurrentAddress type={type} />
                    </FormProvider>
                  )}

                  {currentStep === 3 && type !== ActivityEnum.MOVECLEAN && (
                    <FormProvider {...newAddressFormMethods}>
                      <NewAddress />
                    </FormProvider>
                  )}
                  {currentStep === 3 && type === ActivityEnum.MOVECLEAN && <PriceView type={type} setError={(data: ErrorObject) => setError(data)} />}
                  {currentStep === 4 && type === ActivityEnum.MOVECLEAN && <ContactDetails type={type} isQuotation={isQuotation} />}
                  {currentStep === 5 && type == ActivityEnum.MOVECLEAN && <Confirmation isQuotation={isQuotation} type={type} setError={(data: ErrorObject) => setError(data)} />}

                  {currentStep === 4 && type !== ActivityEnum.MOVECLEAN && <PriceView type={type} setError={(data: ErrorObject) => setError(data)} />}

                  {currentStep === 5 && type !== ActivityEnum.MOVECLEAN && <ContactDetails type={type} isQuotation={isQuotation} />}

                  {currentStep === 6 && type !== ActivityEnum.MOVECLEAN && <Confirmation isQuotation={isQuotation} type={type} setError={(data: ErrorObject) => setError(data)} />}
                </>
              ) : (
                <ErrorPage title={error.title} subTitle={error.subTitle} description={error.description} />
              )}
            </OrderWidgetWrapper>
          </Flex>
        </ContentWrapper>
      </Flex>
    </>
  )
}

export default OrderWidgetTemplate
