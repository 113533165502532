// All the values/types that's used for the selection component in our forms

import { APARTMENT, HOUSE, LARGE_ELEVATOR, NO, NO_ELEVATOR, SMALL_ELEVATOR, TOWNHOUSE, YES } from '@/constants/addressFormConstants'
import { TFunction } from 'next-i18next'

export const getElevatorTypes = (t: TFunction) => {
  const elevatorSizeTypes = [
    { name: NO_ELEVATOR, displayName: t('address:noElevator') },
    { name: SMALL_ELEVATOR, displayName: t('address:smallElevator') },
    { name: LARGE_ELEVATOR, displayName: t('address:largeElevator') },
  ]
  return elevatorSizeTypes
}


export const getResidenceTypes = (t: TFunction) => {
  const residenceTypes = [
    { name: APARTMENT, displayName: t('address:apartment') },
    { name: TOWNHOUSE, displayName: t('address:townHouse') },
    { name: HOUSE, displayName: t('address:villa') },
  ]
  return residenceTypes
}

export const getLimitedAccessibilityTypes = (t: TFunction) => {
  const limitedAccessibilityTypes = [
    { name: NO, displayName: t('common:no') },
    { name: YES, displayName: t('common:yes') },
  ]
  return limitedAccessibilityTypes
}

export const getHeavyLiftingTypes = (t: TFunction) => {
  const heavyLiftingTypes = [
    { name: NO, displayName: t('common:no') },
    { name: YES, displayName: t('common:yes') },
  ]
  return heavyLiftingTypes
}
