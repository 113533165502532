import { styled } from 'stitches.config'

export const AddressWrapper = styled('div', {
  color: '$secondaryMain',
  width: '100%',
  '.smallerInput': {
    width: '90px',
  },
  '.doorCodeInput': {
    width: '150px',
  },
  H3: {
    fontSize: '$5',
    fontWeight: 600,
  },
  '.infoText': {
    fontSize: '$5',
    margin: '16px 0px 16px 0px',
  },
  p: {
    fontSize: '$2',
  },
  '.noMarginBottom': {
    marginBottom: 0,
  },
  '.titleTextContainer': {
    margin: '0 0 10px 0',
  },
  '.titleText': {
    fontWeight: '$bold',
    fontSize: '20px',
    fontStyle: 'normal',
    margin: '0 0 20px',
  },
  '.iconContainer': {
    marginTop: '40px',
    marginBottom: '10px',
  },
  '@sm': {
    '.iconContainer': {
      marginTop: '40px',
    },
    '.titleTextContainer': {
      margin: '10px 0 0 12px',
    },
    '.titleText': {
      fontWeight: '$bold',
      fontSize: '36px',
      fontStyle: 'normal',
    },
    '.inputWrapper': {
      marginTop: '32px',
    },
  },
})

export const MainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '.continueBtn': {
    marginTop: '30px',
  },
  '@sm': {
    display: 'flex',
    flexDirection: 'row',
    '.continueBtn': {
      marginTop: '30px',
    },
  },
})

export const SubContainer = styled('div', {
  width: '100%',
  '@sm': {
    width: '100%',
    padding: '10px 20px 0px 20px',
  },
  variants: {
    side: {
      right: {
        '@sm': {
          marginTop: '195px',
        },
      },
    },
  },
})

export const InputWrapper = styled('div', {
  margin: '15px 0px',
  width: '100%',
})

export const SelectionWrapper = styled('div', {
  width: '100%',
  margin: '16px 0px',
})

export const SizeOfAdditionalSpaceText = styled('div', {
  textAlign: 'left',
  H3: {
    marginBottom: '0px',
  },
  p: {
    marginTop: '2px',
    marginBottom: '0px',
  },
})
