import React, { useRef, useCallback, useState, useMemo } from 'react'
import { animated } from '@react-spring/web'
import Selection from '@/components/molecules/Selection'
import {
  ModalWrapper,
  ContentWrapper,
  MainContainer,
  ServiceIconWrapper,
  TitleText,
  InfoText,
  InfoTexWrapper,
  ActionWrapper,
  InputWrapper,
  MoveCleanPrimaryWrapper,
  MoveCleanSecondayWrapper,
  CommonAddressFormWrapper,
  SelectionWrapper,
} from '../Modal.styles'
import useResponsive from '@/constants/hooks/useResponsive'
import Plus from '@/public/images/Plus.svg'
import Flex from '@/components/atoms/Flex/Flex'
import { StyledButton } from '../Modal.styles'
import Button from '@/components/atoms/Button'
import { HANDYMAN, STORAGE, PACKING, MOVEHELP, MOVECLEAN } from '@/constants/AddonConstants'
import { useTranslation } from 'react-i18next'
import Storage from '@/public/images/Storage.svg'
import Packing from '@/public/images/Packing.svg'
import Handyman from '@/public/images/Handyman.svg'
import MoveClean from '@/public/images/Moveclean.svg'
import MyHouse from '@/public/images/Delivery.svg'
import { getElevatorTypes, getHeavyLiftingTypes, getLimitedAccessibilityTypes, getResidenceTypes } from '@/common/helpers/SelectionTypes'
import MapPin from '@/public/images/MapPin.svg'
import Input from '@/components/atoms/Input'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import CommonAddressForm from '@/components/organisms/CommonAddressForm'
import { APARTMENT, NO, NO_ELEVATOR } from '@/constants/addressFormConstants'
import { useFormAddressContext } from '@/common/context/addressForm/AddressFormProvider'

export const currentAddressFormDefaultValues = {
  autoCompleteAddress: '',
  residenceType: APARTMENT,
  elevatorSize: NO_ELEVATOR,
  limitedAccessibility: NO,
  heavyLifting: NO,
  //doorCode: '',
}
export type DefaultModalProps = {
  showModal: boolean
  setShowModal: (value: boolean) => void
  closeModal: (e: React.MouseEvent) => void
  type: string
  animation: any
  onSelect: (value: string) => void
}

export const DefaultModal = ({ showModal, setShowModal, type, animation, closeModal, onSelect }: DefaultModalProps) => {
  const methods = useForm<AddressInfo>()

  const { isTabletPortraitOrGreater } = useResponsive()
  const [date, setDate] = useState<string | null>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation(['summary', 'address'])
  const { updateNewAddress } = useFormAddressContext()
  const residenceTypeData = useMemo(() => getResidenceTypes(t), [t('apartment')])
  const elevatorTypeData = useMemo(() => getElevatorTypes(t), [t('noElevator')])
  const limitedAccessibilityTypeData = useMemo(() => getLimitedAccessibilityTypes(t), [t('common:no')])
  const heavyLiftingTypeData = useMemo(() => getHeavyLiftingTypes(t), [t('common:no')])

  const onNewAddressSubmit = methods?.handleSubmit(async (data: AddressInfo) => {
    onSelect(type)
    updateNewAddress(data)
    setShowModal(false)
  })

  const {
    watch,
    setValue,
    formState: { errors },
    register,
    getValues,
  } = methods

  const onSelectElevatorType = useCallback(
    (value: 'large' | 'small' | 'none' | string) => {
      setValue('elevatorSize', value)
    },
    [watch('elevatorSize')]
  )

  const onSelectLimitedAccessibility = useCallback(
    (value: string) => {
      setValue('limitedAccessibility', value)
    },
    [watch('limitedAccessibility')]
  )

  const onSelectHeavyLifting = useCallback(
    (value: string) => {
      setValue('heavyLifting', value)
    },
    [watch('heavyLifting')]
  )

  return (
    <>
      {showModal ? (
        <ModalWrapper onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            {type !== MOVEHELP ? (
              <ContentWrapper>
                <MainContainer>
                  <ServiceIconWrapper> {type === HANDYMAN ? <Handyman /> : type === PACKING ? <Packing /> : type === STORAGE ? <Storage /> : <MoveClean />} </ServiceIconWrapper>
                  <TitleText>
                    {type === HANDYMAN
                      ? t('PRICE_VIEW.handyman')
                      : type === PACKING
                      ? t('PRICE_VIEW.packing')
                      : type === STORAGE
                      ? t('PRICE_VIEW.storage')
                      : t('PRICE_VIEW.movecleaning')}
                  </TitleText>
                  <InfoTexWrapper>
                    <InfoText>
                      <p>
                        {type === HANDYMAN
                          ? t('summary:PRICE_VIEW.MODAL.handyman')
                          : type === PACKING
                          ? t('summary:PRICE_VIEW.MODAL.packing')
                          : type === STORAGE
                          ? t('summary:PRICE_VIEW.MODAL.storage')
                          : t('summary:PRICE_VIEW.MODAL.movecleaning')}
                      </p>
                    </InfoText>
                    {type !== MOVECLEAN && (
                      <InfoText>
                        <strong>{t('summary:PRICE_VIEW.MODAL.addon')}</strong>
                      </InfoText>
                    )}
                  </InfoTexWrapper>

                  {type === MOVECLEAN && (
                    <InputWrapper>
                      <input className="selectDate" type="date" onChange={(e) => setDate(e.target.value)} />
                    </InputWrapper>
                  )}
                  <Flex direction={'row'} justifyContent={'space-between'}>
                    <ActionWrapper onClick={() => setShowModal(false)}>
                      <TitleText>
                        <p style={{ cursor: 'pointer', padding: '15px 0 0' }}>{t('summary:PRICE_VIEW:noThankYou')}</p>
                      </TitleText>
                    </ActionWrapper>
                    <ActionWrapper>
                      <StyledButton>
                        <Button
                          onClick={() => {
                            onSelect(type)
                            setShowModal(false)
                          }}
                          text={t('summary:PRICE_VIEW:add')}
                          variant="primaryAlt"
                          iconLeft={<Plus />}
                          disabled={false}
                          fontSize={14}
                        />
                      </StyledButton>
                    </ActionWrapper>
                  </Flex>
                </MainContainer>
              </ContentWrapper>
            ) : (
              <MoveCleanPrimaryWrapper>
                <MoveCleanSecondayWrapper>
                  <Flex className="serviceIcon" direction={isTabletPortraitOrGreater ? 'row' : 'column'} justifyItems={'center'} alignItems={'center'}>
                    <MyHouse width={55} height={50} />
                    <h4 className="paddingText">{t('summary:PRICE_VIEW:moveHelp')}</h4>
                  </Flex>
                  <p className="paddingText">{t('summary:PRICE_VIEW.MODAL.movehelp')}</p>
                  <div className="contentWrapper">
                    <InputWrapper>{/* <input className="selectDate" type="date" onChange={(e) => setDate(e.target.value)} /> */}</InputWrapper>
                  </div>
                  <div className="contentWrapper">
                    <FormProvider {...methods}>
                      <CommonAddressFormWrapper>
                        <CommonAddressForm frontIcon={<MapPin />} />
                      </CommonAddressFormWrapper>
                    </FormProvider>
                  </div>
                  <Flex direction="row" justifyContent="start">
                    <InputWrapper style={{ width: '70%', marginLeft: '10%' }}>
                      <Selection data={residenceTypeData} onSelectValue={onSelectElevatorType} selectedValue={watch('elevatorSize')} />
                    </InputWrapper>
                  </Flex>
                  <Flex direction="row" justifyContent="space-between" alignItems="center" className="inputContainer">
                    <p className="noMarginBottom">{t('summary:PRICE_VIEW.live')}</p>
                    <div className="smallerInput">
                      <Input
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        label={t<string>('address:sqm')}
                        {...methods.register('residenceSize', { valueAsNumber: true })}
                        error={errors.residenceSize?.message}
                        defaultValue={isNaN(getValues().residenceSize) ? undefined : getValues().residenceSize}
                      />
                    </div>
                  </Flex>
                  <Flex direction="row" justifyContent="space-between" alignItems="center" className="inputContainer">
                    <p className="noMarginBottom">{t('summary:PRICE_VIEW.live')}</p>
                    <div className="smallerInput">
                      <Input
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        label={t<string>('address:sqm')}
                        {...register('residenceSize', { valueAsNumber: true })}
                        error={errors.residenceSize?.message}
                        defaultValue={isNaN(getValues().residenceSize) ? undefined : getValues().residenceSize}
                      />
                    </div>
                  </Flex>
                  <Flex direction="row" justifyContent="space-between" alignItems="center" className="inputContainer">
                    <p className="noMarginBottom">{t('summary:PRICE_VIEW.floor')}</p>
                    <div className="smallerInput">
                      <Input
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        label={t<string>('address:sqm')}
                        {...register('residenceSize', { valueAsNumber: true })}
                        error={errors.residenceSize?.message}
                        defaultValue={isNaN(getValues().residenceSize) ? undefined : getValues().residenceSize}
                      />
                    </div>
                  </Flex>
                  {/* 
                  <Flex direction="row" justifyContent="space-between" alignItems="center" className="inputContainer">
                    <p className="noMarginBottom">{t('summary:PRICE_VIEW.gate')}</p>
                    <div className="doorCodeInput">
                      <Input
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        label={t<string>('address:sqm')}
                        {...register('residenceSize', { valueAsNumber: true })}
                        error={errors.residenceSize?.message}
                        defaultValue={isNaN(getValues().residenceSize) ? undefined : getValues().residenceSize}
                      />
                    </div>
                  </Flex> */}
                </MoveCleanSecondayWrapper>
                <MoveCleanSecondayWrapper>
                  <SelectionWrapper>
                    <Flex direction="column" justifyContent="space-between" alignItems="start">
                      <h4 className="noMarginBottom">{t('summary:PRICE_VIEW.elevator')}</h4>
                      <p style={{ marginBottom: 10 }}> {t('summary:PRICE_VIEW.elevatorInfo')}</p>
                    </Flex>
                    <InputWrapper>
                      <Selection data={elevatorTypeData} onSelectValue={onSelectElevatorType} selectedValue={watch('elevatorSize')} />
                    </InputWrapper>
                  </SelectionWrapper>
                  <SelectionWrapper>
                    <Flex direction="column" justifyContent="space-between" alignItems="start">
                      <h4 className="noMarginBottom"> {t('PRICE_VIEW.accessibility')}</h4>
                      <p style={{ marginBottom: 10 }}>{t('summary:PRICE_VIEW.accessibilityInfo')}</p>
                    </Flex>
                    <InputWrapper>
                      <Selection data={limitedAccessibilityTypeData} onSelectValue={onSelectLimitedAccessibility} selectedValue={watch('limitedAccessibility')} />
                    </InputWrapper>
                  </SelectionWrapper>
                  <SelectionWrapper>
                    <Flex direction="column" justifyContent="space-between" alignItems="start">
                      <h4 className="noMarginBottom"> {t('PRICE_VIEW.heavyLifting')}</h4>
                      <p style={{ marginBottom: 10 }}>{t('summary:PRICE_VIEW.heavyLiftingInfo')}</p>
                    </Flex>
                    <InputWrapper>
                      <Selection data={heavyLiftingTypeData} onSelectValue={onSelectHeavyLifting} selectedValue={watch('heavyLifting') || NO} />
                    </InputWrapper>
                  </SelectionWrapper>
                  <Flex direction={'row'} justifyContent={'center'} style={{ margin: '0 0 20px 0' }}>
                    <ActionWrapper onClick={() => setShowModal(false)}>
                      <TitleText>
                        <p style={{ cursor: 'pointer', padding: '15px 0 0' }}>{t('summary:PRICE_VIEW.noThankYou')}</p>
                      </TitleText>
                    </ActionWrapper>
                    <ActionWrapper>
                      <StyledButton>
                        <Button onClick={onNewAddressSubmit} text={t('summary:PRICE_VIEW.add')} variant="primaryAlt" iconLeft={<Plus />} disabled={false} fontSize={14} />
                      </StyledButton>
                    </ActionWrapper>
                  </Flex>
                </MoveCleanSecondayWrapper>
              </MoveCleanPrimaryWrapper>
            )}
          </animated.div>
        </ModalWrapper>
      ) : null}
    </>
  )
}
