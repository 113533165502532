import { styled, keyframes } from 'stitches.config'

const rotator = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
})

const duration = '1.4s'
const offset = 187

const dash = keyframes({
  '0%': { strokeDashoffset: `${offset}`, transform: 'rotate(0)' },
  '50%': { strokeDashoffset: `${offset / 3}`, transform: 'rotate(45deg)' },
  '100%': { strokeDashoffset: `${offset}`, transform: 'rotate(360deg)' },
})

export const SvgWrapper = styled('div', {
  transform: 'scale(1.0)',
  variants: {
    scale: {
      1: {
        transform: 'scale(1.0)',
      },
      1.5: {
        transform: 'scale(1.5)',
      },
      2: {
        transform: 'scale(2.0)',
      },
    },
  },
})

export const StyledSpinner = styled('div', {
  height: '24px',
  svg: {
    stroke: '#ffb880',
    strokeWidth: '4px',
    animation: `2s linear infinite ${rotator}`,
    circle: {
      display: 'block',
      strokeDasharray: `${offset}`,
      strokeDashoffset: 0,
      strokeLinecap: 'round',
      animation: `${duration} ease-in-out infinite both ${dash}`,
      transformOrigin: '50% 50%',
    },
  },
})
