import React, { useState } from 'react'
import * as yup from 'yup'
import { ErrorPageWrapper, InputWrapper, StyledButton } from './ErrorPage.Styles'
import ErrorIcon from '@/public/images/ErrorIcon.svg'
import H2 from '@/components/atoms/H2'
import Input from '@/components/atoms/Input'
import { useTranslation } from 'react-i18next'
import Button from '@/components/atoms/Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import getOrganizationUser from '@/helpers/getOrganizationUser'
import { useRouter } from 'next/router'
import va from '@vercel/analytics'
import { useEffectOnce } from 'react-use'
import { useSupplierIdContext } from '@/common/context/supplierId/SupplierIdProvider'

interface ErrorPageProps {
  title: string
  subTitle?: string
  description: string
}

interface ContactSupportForm {
  phoneNumber: number
}

const ErrorPage = ({ title, subTitle, description }: ErrorPageProps) => {
  const { supplierId } = useSupplierIdContext()
  const [showMessage, setShowMessage] = useState(false)
  const router = useRouter()
  const { organizationId } = router.query
  const WEBHOOK = process.env.NEXT_PUBLIC_ZAPIER_WEBHOOK
  const { t } = useTranslation(['common'])
  const contactSupportSchema = yup
    .object({
      phoneNumber: yup.string().matches(new RegExp('([0-9])|(\\+[0-9])'), t<string>('errors:invalidPhone')).max(12, t<string>('errors:invalidPhone')),
    })
    .required()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ContactSupportForm>({ resolver: yupResolver(contactSupportSchema) })

  const url = window.location != window.parent.location ? document.referrer : document.location.href

  const onSubmit = handleSubmit(async (data: ContactSupportForm) => {
    const JSONObject = {
      error: subTitle,
      phoneNumber: data.phoneNumber,
      url: url,
    }

    if (
      subTitle === t<string>(`errors:MESSAGE_KEYS.residence_too_big`) ||
      subTitle === t<string>(`errors:MESSAGE_KEYS.long_distance_move`) ||
      subTitle === t<string>(`errors:MESSAGE_KEYS.SUPPLIER_NOT_FOUND`)
    ) {
      const organizationUser = await getOrganizationUser(organizationId as string)
      Object.assign(JSONObject, { toAddress: organizationUser?.email })
    } else {
      Object.assign(JSONObject, { toAddress: process.env.NEXT_PUBLIC_ZAPIER_RECIPIENT_EMAIL })
    }

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify(JSONObject),
    }

    await fetch(WEBHOOK as string, options)
      .then(() => setShowMessage(true))
      .catch((error) => console.log(error))
  })

  useEffectOnce(() => {
    va.track('ERROR_PAGE', {
      message: subTitle || '',
      supplierId: supplierId,
    })
  })

  return (
    <ErrorPageWrapper>
      <ErrorIcon width="149" height="148" />
      <div className="textWrapper">
        <H2>{title}</H2>
        <p>{subTitle}</p>
        <p>{description}</p>
      </div>
      <div className="inputContainer">
        <InputWrapper>
          <Input
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            label={t<string>('telephoneNumber')}
            {...register('phoneNumber', { valueAsNumber: true })}
            error={errors.phoneNumber?.message}
          />
        </InputWrapper>
        <StyledButton>{showMessage ? <p>{t<string>('thanks')}</p> : <Button onClick={onSubmit} text={t('okay')} variant="primaryAlt" />}</StyledButton>
      </div>
    </ErrorPageWrapper>
  )
}

export default ErrorPage
