import { styled } from 'stitches.config'

// https://dev.to/lexswed/creating-api-for-components-flexbox-layout-4cim

const Flex = styled('div', {
  display: 'flex',

  variants: {
    display: {
      flex: {
        display: 'flex',
      },
      inline: {
        display: 'inline-flex',
      },
    },
    direction: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
      },
      'row-reverse': {
        flexDirection: 'row-reverse',
      },
      'column-reverse': {
        flexDirection: 'column-reverse',
      },
    },
    justifyContent: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
      stretch: {
        justifyContent: 'stretch',
      },
      'space-between': {
        justifyContent: 'space-between',
      },
    },
    justifyItems: {
      start: {
        justifyItems: 'flex-start',
      },
      center: {
        justifyItems: 'center',
      },
      end: {
        justifyItems: 'flex-end',
      },
      stretch: {
        justifyItems: 'stretch',
      },
      baseline: {
        justifyItems: 'baseline',
      },
    },
    justifySelf: {
      start: {
        justifySelf: 'flex-start',
      },
      center: {
        justifySelf: 'center',
      },
      end: {
        justifySelf: 'flex-end',
      },
      stretch: {
        justifySelf: 'stretch',
      },
      baseline: {
        justifySelf: 'baseline',
      },
    },
    alignContent: {
      start: {
        alignContent: 'flex-start',
      },
      center: {
        alignContent: 'center',
      },
      end: {
        alignContent: 'flex-end',
      },
      stretch: {
        alignContent: 'stretch',
      },
      'space-between': {
        alignContent: 'space-between',
      },
    },
    alignItems: {
      start: {
        alignItems: 'flex-start',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'flex-end',
      },
      stretch: {
        alignItems: 'stretch',
      },
      baseline: {
        alignItems: 'baseline',
      },
    },
    alignSelf: {
      start: {
        alignSelf: 'flex-start',
      },
      center: {
        alignSelf: 'center',
      },
      end: {
        alignSelf: 'flex-end',
      },
      stretch: {
        alignSelf: 'stretch',
      },
      baseline: {
        alignSelf: 'baseline',
      },
    },
    wrap: {
      wrap: {
        flexWrap: 'wrap',
      },
      noWrap: {
        flexWrap: 'nowrap',
      },
      reverse: {
        flexWrap: 'wrap-reverse',
      },
    },
  },
})
export default Flex
