import React, { ReactElement } from 'react'
import { ChipWrapper, InnerChip, IconLeftWrapper, IconRightWrapper } from './Chip.style'

export type ChipProps = {
  text: string | null
  variant?: 'primary' | 'ghost' | 'ghostInverted' | 'primaryAlt' | 'primaryAltInverted' | 'gray' | 'outline' | 'green'
  iconLeft?: ReactElement | null
  iconRight?: ReactElement | null
  opacity?: number
  fontSize?: number
  padding?: string
}

const Chip = ({ text, variant = 'primary', iconLeft, iconRight, opacity = 1, fontSize = 16, padding = '' }: ChipProps) => {
  return (
    <ChipWrapper>
      <InnerChip itemType="button" variant={variant} css={{ fontSize: fontSize, opacity: opacity, padding: padding }}>
        {iconLeft && <IconLeftWrapper data-testid="iconRight">{iconLeft}</IconLeftWrapper>}+{text}kr
        {iconRight && <IconRightWrapper data-testid="iconRight">{iconRight}</IconRightWrapper>}
      </InnerChip>
    </ChipWrapper>
  )
}

export default Chip
