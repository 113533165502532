import Flex from '@/components/atoms/Flex'
import { styled } from 'stitches.config'

export const ConfirmationWrapper = styled('div', {
  color: '$secondaryMain',
  width: '100%',
  '.spinnerWrapper': {
    marginTop: '100px',
  },
})

export const OrderStatusText = styled('p', {
  fontSize: '$6',
  fontWeight: '$bold',
})

export const OrderProccessTextWrapper = styled('div', {
  marginTop: '40px',
})

export const OrderCompleteWrapper = styled(Flex, {
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 10px',
  '.magicIcon': { marginLeft: '25px', marginTop: '30px' },
  p: {
    margin: '5px',
    textAlign: 'center',
  },
})

export const GroundWrapper = styled('div', {
  position: 'fixed',
  left: '0px',
  bottom: '0px',
  width: '100%',
})

export const HousesWrapper = styled('div', {
  position: 'fixed',
  bottom: '167px',
})

export const MyHouseWrapper = styled('div', {
  position: 'fixed',
  left: '0px',
})

export const LorryWrapper = styled('div', {
  position: 'fixed',
  marginTop: '8px',
  variants: {
    lorryPosition: {
      start: { left: '65px' },
      middle: { left: '45%' },
      end: { right: '65px' },
    },
  },
})

export const NewHouseWrapper = styled('div', {
  position: 'fixed',
  right: '0px',
})

export const StyledButton = styled('div', {
  height: '48px',
  width: '171px',
  marginTop: '30px',
})

export const StyledLink = styled('a', {
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  padding: 0,
  borderRadius: '$button',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 700,
  fontSize: '$2',
  background: '$accentLight',
  border: '2px solid $accentLight',
  color: '$secondaryMain',
  ' &:active': {
    background: '$accentMain',
  },
  ' &:hover': {
    background: '$accentMain',
    color: '$blackMain',
    border: '2px solid $accentMain',
  },
})
