import React from 'react'
import { FooterWrapper } from './Footer.styles'
import useResponsive from '@/constants/hooks/useResponsive'
import FooterDesktop from 'public/images/Footer.svg'
import FooterMobile from 'public/images/FooterMobile.svg'

// export interface FooterProps {}

const Footer = () => {
  const { isTabletPortraitOrGreater } = useResponsive()
  return <FooterWrapper data-testid="wrapper">{isTabletPortraitOrGreater ? <FooterDesktop width={'100%'} /> : <FooterMobile width={'100%'} />}</FooterWrapper>
}

export default Footer
