import { styled } from 'stitches.config'

export const ModalWrapper = styled('div', {
  display: 'flex',
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 1000,
  top: 0,
  left: 0,
  right: 0,
  '@sm': {
    position: 'fixed',
  },
  '.selectDate': {
    width: '95%',
    height: '50px',
    padding: '14px 12px',
    outline: 'none',
    borderRadius: '$input',
    border: '1.9px solid rgba(118, 118, 118, 0.4)',
  },
  '.smallerInput': {
    width: '69px',
    padding: '10px',
  },
  '.doorCodeInput': {
    width: '150px',
    padding: '10px',
  },
  '.noMarginBottom': {
    padding: '0px',
  },
  '.paddingText': {
    padding: '10px',
  },
  '.serviceIcon': {
    marginLeft: '10px',
  },
  '.inputContainer': {
    paddingLeft: '20px',
    paddingRight: '50px',
  },
  '.contentWrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export const ContentWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  background: '#fff',
  borderRadius: '10px',
  boxShadow: '0 5px 16px rgba(0,0,0,0.2)',
  position: 'absolute',
  bottom: '-5px',
  padding: '30px 0 40px',
  '@sm': {
    display: 'flex',
    width: '733px',
    height: '415px',
    borderRadius: '10px',
    background: '#fff',
    boxShadow: '0 5px 16px rgba(0,0,0,0.2)',
    left: '50%',
    top: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
  },
})

export const MainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '.continueBtn': {
    marginTop: '30px',
  },
  '@sm': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export const MoveCleanPrimaryWrapper = styled(ContentWrapper, {
  flexDirection: 'column',
  '@sm': {
    maxHeight: '95%',
    minHeight: '600px',
    flexDirection: 'row',
  },
})

export const MoveCleanSecondayWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '0 20px',
  '@sm': {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    width: '100%',
    padding: '0',
  },
})

export const ServiceIconWrapper = styled('div', {
  margin: '0px 10px 0px 0px',
  '@sm': {
    margin: '0px',
  },
})

export const TitleText = styled('div', {
  fontSize: '$5',
  color: '$secondaryMain',
  fontWeight: '$bold',
  textAlign: 'center',
  letterSpacing: ' 0.04em',
  marginTop: '5px',
  '@sm': {
    fontSize: '$7',
  },
})

export const InfoTexWrapper = styled('div', {
  width: '274px',
  '@sm': {
    width: '324px',
  },
})

export const InfoText = styled('div', {
  fontSize: '$5',
  color: '$secondaryMain',
  fontWeight: '$normal',
  lineHeight: '1.6',
  margin: '10px 0 0 0',
  textAlign: 'center',
  sm: {
    fontSize: '$5',
    color: '$secondaryMain',
    fontWeight: '$normal',
    lineHeight: '1.6',
    margin: '10px 0 0 0',
  },
})

export const ActionWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-around',
  margin: '0 10px 0 10px',

  '@sm': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    margin: '0 15px 0 15px',
  },
})

export const PlusIconWrapper = styled('div', {
  width: '28px',
  height: '28px',
  margin: '25px 5px 0px 0px',
  '@sm': {
    width: '20px',
    height: '20px',
    margin: '25px 5px 0px 0px',
  },
})

export const StyledButton = styled('div', {
  width: '120px',
  height: '35px',
  margin: '20px 0 0 0 ',
})

export const InputWrapper = styled('div', {
  display: 'flex',
  margin: '20px 0px 0',
  width: '70%',
  alignItems: 'center',
  justifyContent: 'center',
  '@sm': {
    display: 'flex',
    width: '70%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
export const CommonAddressFormWrapper = styled('div', {
  width: '71%',
  padding: '10px',
  '@sm': {
    width: '71%',
    padding: '10px',
  },
})

export const SelectionWrapper = styled('div', {
  width: '100%',
  '@sm': {
    width: '100%',
    margin: '5px 0px 0px 20px',
  },
})
