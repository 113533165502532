import React from 'react'
import { useCombobox } from 'downshift'
import Input from '@/components/atoms/Input'
import { ComboboxStyles, ItemStyle, MenuStyles, Wrapper } from './Autocomplete.styles'

type AutoSelectProps = {
  items: { value: string; label: string }[]
  label: string
  placeholder?: string
  onChange: (value: string | undefined) => void
  onSelect: (value: string) => void
  className?: string
  name?: string
  value?: string | null
  frontIcon?: React.ReactNode
  error?: string
}

export const Autocomplete = React.forwardRef<HTMLInputElement, AutoSelectProps>((props, ref) => {
  const { items, label, placeholder, onChange, onSelect, value, className, frontIcon, error } = props
  const { isOpen, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps } = useCombobox({
    items: items,
    itemToString(item) {
      return item ? item.label : ''
    },
    onInputValueChange: (item) => {
      onChange(item?.inputValue)
    },
    onSelectedItemChange: (item) => {
      onSelect(item?.selectedItem?.value || '')
    },
  })

  const inputPropsValues = { ref, label, placeholder, ...(value ? { value } : null) }

  return (
    <Wrapper className={className}>
      <ComboboxStyles {...getComboboxProps()}>
        <Input
          {...getInputProps(inputPropsValues)}
          value={value}
          placeholder={placeholder}
          label={label}
          withCursorPositionUpdate
          frontIcon={frontIcon}
          style={{ paddingLeft: '30px' }}
          error={error}
        />
      </ComboboxStyles>
      <MenuStyles {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => {
            return (
              <ItemStyle noHits={item.value === 'noHits'} active={highlightedIndex === index} key={`${item}${index}`} {...getItemProps({ item, index })}>
                {item.label}
              </ItemStyle>
            )
          })}
      </MenuStyles>
    </Wrapper>
  )
})
