import { styled } from 'stitches.config'
import { Autocomplete } from '@/components/molecules/Autocomplete'

export const CommonAddressWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})

export const FlexRow = styled('div', {
  display: 'flex',
  rowGap: '10px',
  padding: '3px 0',
  variants: {
    withErrorPadding: {
      true: {
        padding: '3px 3px 15px 3px',
      },
    },
  },
})
export const AutoCompleteStyled = styled(Autocomplete, {
  width: '100%',
  fontSize: '$5',
})

export const Label = styled('label', {
  position: 'absolute',
  left: '11px',
  top: '7.5px',
  color: 'gray',
  pointerEvents: 'none',
  display: 'flex',
  transition: '0.2s ease',
  '&:before': {
    transition: 'all 0.2s ease',
    position: 'absolute',
    height: '50%',
    width: '100%',
    content: '',
  },
  '&:after': {
    transition: 'all 0.2s ease',
    position: 'absolute',
    height: '50%',
    width: '100%',
    content: '',
    top: '50%',
  },
})

export const ErrorText = styled('div', {
  color: '#f25a43',
  position: 'absolute',
  fontSize: '12px',
})

export const Input = styled('input', {
  height: '40px',
  width: ' 100%',
  padding: '14px 10px',
  outline: 'none',
  borderRadius: '5px',
  border: '1.9px solid rgba(118, 118, 118, 0.4)',
  fontWeight: 400,
  fontSize: '16px',
  transition: 'border 10ms',
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: '0',
  },
  color: 'rgba(0, 0, 0, 0.7)',
  '&:focus': {
    border: '2px solid $primaryMain',
    transition: 'border 10ms',
  },
  '&:focus + .label': {
    top: '-9px',
    fontSize: '12px',
    backgroundColor: ' white',
    padding: '0 2px',
    color: '$primaryMain',
    left: '10px',
  },
  variants: {
    hasValue: {
      true: {
        border: '1.9px solid rgba(118, 118, 118, 0.4)',
        transition: 'border 10ms',
        '+ .label': {
          top: '-9px',
          fontSize: '12px',
          backgroundColor: 'white',
          padding: '0 2px',
          color: 'rgba(0, 0, 0, 0.7)',
          left: '10px',
        },
      },
    },
    error: {
      true: {
        border: '1.9px solid #F25A43',
        transition: 'border 10ms',
        '+ .label': {
          top: '-9px',
          fontSize: '12px',
          backgroundColor: 'white',
          padding: '0 2px',
          color: '#F25A43',
          left: ' 10px',
        },
      },
    },
  },
})

export const InputWrapper = styled('div', {
  width: '100%',
  maxWidth: '100%',
  position: 'relative',
  padding: '4px 4px 8px 4px',
  boxSizing: 'border-box',
})

export const City = styled('div', {
  maxWidth: '60%',
  flexGrow: '1',
  padding: '4px',
  height: '60px',
})

export const AutoCompleteWrapper = styled('div', {
  width: '100%',
  flexGrow: '1',
  height: '60px',
  overflowY: 'visible',
  color: 'black',
  fontSize: '14px',
  fontWeight: '400',
})

export const StreetNumber = styled('div', {
  flexGrow: '0',
  padding: '4px',
  height: '60px',
  flexBasis: '45%',
  fontWeight: '$normal',
})

export const ZipCode = styled('div', {
  flexGrow: '0',
  height: '60px',
  flexBasis: '40%',
  fontWeight: '$normal',
})

export const CityWrapper = styled('div', {
  flexGrow: '1',
  paddingLeft: '6px',
  height: '60px',
  flexBasis: '66%',
})
