import React, { useRef, useEffect, useCallback } from 'react'
import { useSpring } from '@react-spring/web'
import { DefaultModal } from './Content/DefaultModal'
import { useFormContext } from 'react-hook-form'

export type ModalProps = {
  showModal: boolean
  setShowModal: (value: boolean) => void
  type: string
  onSelect: (value: string) => void
}

export const Modal = ({ showModal, setShowModal, type, onSelect }: ModalProps) => {
  const methods = useFormContext<AddressInfo>()
  const modalRef = useRef<HTMLDivElement>(null)
  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModal ? 1 : 0,
    width: `100%`,
    background: 'rgba(0,0,0,0.1)',
  })

  const closeModal = (e: React.MouseEvent) => {
    if (modalRef.current === e.target) {
      setShowModal(false)
    }
  }

  const keyPress = useCallback(
    (e: { key: string }) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false)
      }
    },
    [setShowModal, showModal]
  )

  useEffect(() => {
    document.addEventListener('keydown', keyPress)
    return () => document.removeEventListener('keydown', keyPress)
  }, [keyPress])

  return (
    <>
      <DefaultModal onSelect={onSelect} showModal={showModal} setShowModal={setShowModal} type={type} closeModal={closeModal} animation={animation} />
    </>
  )
}

export default Modal
