import React, { useState, useRef, useMemo, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  AccordionButtonWrapper,
  AccordionContentWrapper,
  AccordionItemHeading,
  AccordionItemView,
  AccordionItemWrapper,
  ChangeDetailsText,
  ContactDetailsText,
  ContactDetailsWrapper,
  DetailsWrapper,
  DottedLine,
  IconWrapper,
  InputWrapper,
  MainContainer,
  PermissionText,
  PermissionWrapper,
  PopupText,
  PopupWrapper,
  Seperator,
  ServiceDetailsText,
  StyledAccordionItem,
  StyledButton,
  SubContainer,
  TextWrapper,
} from './ContactDetails.Styles'
import H2 from '@/components/atoms/H2'
import Input from '@/components/atoms/Input'
import Popup from '@/components/atoms/Popup'
import Button from '@/components/atoms/Button'
import { useCurrentStepContext } from '@/common/context/steps/CurrentStepProvider'
import scrollToRef from '@/common/utils/scroll'
import MovingFromIconSmall from '@/public/images/MovingFromIconSmall.svg'
import NewAddressIcon from '@/public/images/NewHouse.svg'
import { getElevatorTypes } from '@/common/helpers/SelectionTypes'
import { Accordion, AccordionTrigger, AccordionContent } from '@/components/molecules/Accordion'
import H3 from '@/components/atoms/H3'
import { MOVEHELP } from '@/constants/AddonConstants'

import { getAddonTypes } from '@/common/helpers/AddonTypes'
import ActivityEnum from '@/common/enums/ActivityEnum'
import { useContactInfoContext } from '@/common/context/contactInfo/ContactInfoProvider'
import { useFormAddressContext } from '@/common/context/addressForm/AddressFormProvider'

const FROM_ADDRESS_KEY = 'from-address'
const TO_ADDRESS_KEY = 'to-address'
const ADDRESS_KEY = 'address'
export interface ContactDetailsProps {
  type: string
  isQuotation: boolean
}
export interface ViewDetailsProps {
  heading: string
  details: string
  extraHorizontalPadding?: boolean
}
const ViewDetials = ({ heading, details, extraHorizontalPadding = false }: ViewDetailsProps) => {
  return (
    <>
      <DottedLine />
      <DetailsWrapper extraHorizontalPadding={extraHorizontalPadding}>
        <H3>{heading}</H3>
        <p>{details}</p>
      </DetailsWrapper>
    </>
  )
}

const ContactDetails = ({ isQuotation }: ContactDetailsProps) => {
  const fromAddressRef = useRef<HTMLDivElement>(null)
  const toAddressRef = useRef<HTMLDivElement>(null)
  const addressRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation(['contactDetails', 'common', 'errors', 'address', 'summary', 'addon'])
  const addonTypeData = useMemo(() => getAddonTypes(t), [t('addon:storageTitle')])
  const { updateContactInfo } = useContactInfoContext()
  const { incrementStep, serviceType, goToStep, addons } = useCurrentStepContext()
  const elevatorTypeData = getElevatorTypes(t)
  const { newAddressInfo, currentAddressInfo } = useFormAddressContext()
  const currentElevatorType = elevatorTypeData.find((d) => d.name === currentAddressInfo.elevatorSize)?.displayName
  const newElevatorType = elevatorTypeData.find((d) => d.name === newAddressInfo.elevatorSize)?.displayName

  const contactDetailsSchema = yup
    .object({
      firstName: yup.string().required(t<string>('errors:emptyFirstName')),
      lastName: yup.string().required(t<string>('errors:emptyLastName')),
      socialSecurityNumber: yup
        .string()
        .required(t<string>('errors:invalidSocialSecurityNumber'))
        .min(12, t<string>('errors:invalidSocialSecurityNumber'))
        .max(12, t<string>('errors:invalidSocialSecurityNumber')),
      email: yup.string().email(t<string>('errors:invalidEmail')),
      phoneNumber: yup.string().matches(new RegExp('([0-9])|(\\+[0-9])'), t<string>('errors:invalidPhone')).max(12, t<string>('errors:invalidPhone')),
    })
    .required()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ContactInfo>({
    resolver: yupResolver(contactDetailsSchema),
  })

  const onBookingSubmit = handleSubmit(async (data: ContactInfo) => {
    updateContactInfo(data)
    incrementStep()
  })

  const buttonText = isQuotation ? 'common:sendQuotation' : 'common:bookOrder'

  const onAccordionValueChange = (value: string) => {
    if (value === FROM_ADDRESS_KEY) {
      scrollToRef(fromAddressRef)
    } else if (value === TO_ADDRESS_KEY) {
      scrollToRef(toAddressRef)
    }
  }

  const showToAddress = serviceType === ActivityEnum.MOVEHELP || (serviceType === ActivityEnum.MOVECLEAN && addons.includes(MOVEHELP))

  return (
    <ContactDetailsWrapper>
      <MainContainer>
        <SubContainer>
          <H2>{t('yourInformation')}</H2>
          <p className="contactDetailsInfoText">{isQuotation ? t('sendQuote') : t('infoText')}</p>
          <Accordion type="single" collapsible onValueChange={(value: string) => onAccordionValueChange(value)}>
            <>
              <AccordionItemView ref={fromAddressRef}>
                <AccordionItemHeading>{t('from')}</AccordionItemHeading>
                <AccordionItemWrapper>
                  <StyledAccordionItem key={FROM_ADDRESS_KEY} value={FROM_ADDRESS_KEY}>
                    <AccordionButtonWrapper>
                      <AccordionTrigger>
                        <>
                          <IconWrapper>
                            <MovingFromIconSmall />
                          </IconWrapper>
                          <TextWrapper>
                            <H3>
                              {currentAddressInfo.streetNumber} {currentAddressInfo.street}
                            </H3>
                            <p>
                              {currentAddressInfo.zip} {currentAddressInfo.city}
                            </p>
                          </TextWrapper>
                        </>
                      </AccordionTrigger>
                    </AccordionButtonWrapper>
                    <AccordionContent noPadding>
                      <AccordionContentWrapper>
                        <ViewDetials heading={t('address:livingSpace')} details={`${currentAddressInfo.residenceSize} ${t<string>('address:sqm')}`} />
                        <ViewDetials heading={t('address:sizeOfAdditionalSpace')} details={`${currentAddressInfo.sizeOfAdditionalSpace} ${t<string>('address:sqm')}`} />
                        {/* <ViewDetials heading={t('address:doorCode')} details={currentAddressInfo.doorCode} /> */}
                        <ViewDetials heading={t('address:details')} details={currentElevatorType || ''} />
                        <DottedLine />
                        <ChangeDetailsText onClick={() => goToStep(2)}>{t('common:changeDetails')}</ChangeDetailsText>
                      </AccordionContentWrapper>
                    </AccordionContent>
                  </StyledAccordionItem>
                </AccordionItemWrapper>
              </AccordionItemView>
              {showToAddress && (
                <AccordionItemView ref={toAddressRef}>
                  <AccordionItemHeading>{t('to')}</AccordionItemHeading>
                  <AccordionItemWrapper>
                    <StyledAccordionItem key={TO_ADDRESS_KEY} value={TO_ADDRESS_KEY}>
                      <AccordionButtonWrapper>
                        <AccordionTrigger>
                          <>
                            <IconWrapper>
                              <NewAddressIcon />
                            </IconWrapper>
                            <TextWrapper>
                              <H3>
                                {newAddressInfo.streetNumber} {newAddressInfo.street}
                              </H3>
                              <p>
                                {newAddressInfo.zip} {newAddressInfo.city}
                              </p>
                            </TextWrapper>
                          </>
                        </AccordionTrigger>
                      </AccordionButtonWrapper>
                      <AccordionContent noPadding>
                        <AccordionContentWrapper>
                          <ViewDetials heading={t('address:livingSpace')} details={`${newAddressInfo.residenceSize} ${t<string>('address:sqm')}`} />
                          <ViewDetials heading={t('address:sizeOfAdditionalSpace')} details={`${newAddressInfo.sizeOfAdditionalSpace} ${t<string>('address:sqm')}`} />
                          {/* <ViewDetials heading={t('address:doorCode')} details={newAddressInfo.doorCode} /> */}
                          <ViewDetials heading={t('address:details')} details={newElevatorType || ''} />
                          <DottedLine />
                          <ChangeDetailsText onClick={() => goToStep(3)}>{t('common:changeDetails')}</ChangeDetailsText>
                        </AccordionContentWrapper>
                      </AccordionContent>
                    </StyledAccordionItem>
                  </AccordionItemWrapper>
                </AccordionItemView>
              )}
            </>
          </Accordion>
        </SubContainer>
        <SubContainer>
          <InputWrapper>
            <Input type="text" label={t<string>('firstName')} {...register('firstName')} error={errors.firstName?.message} />
          </InputWrapper>
          <InputWrapper>
            <Input type="text" label={t<string>('lastName')} {...register('lastName')} error={errors.lastName?.message} />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              label={t<string>('socialSecurityNumber')}
              {...register('socialSecurityNumber', { valueAsNumber: true })}
              error={errors.socialSecurityNumber?.message}
            />{' '}
            <PopupWrapper>
              <Popup title={t<string>('socialSecurityQuestion')} text={<PopupText>{t('socialSecurityQuestionDescription')}</PopupText>} textMargin>
                <p className="secuirtyNoText">{t('socialSecurityQuestion')}</p>
              </Popup>
            </PopupWrapper>
          </InputWrapper>
          <InputWrapper>
            <ContactDetailsText>{t('common:contactDetails')}</ContactDetailsText>
            <Seperator />
            <Input
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              label={t<string>('common:telephoneNumber')}
              {...register('phoneNumber', { valueAsNumber: true })}
              error={errors.phoneNumber?.message}
            />
          </InputWrapper>
          <InputWrapper>
            <Input type="text" label={t<string>('common:email')} {...register('email')} error={errors.email?.message} />
          </InputWrapper>
          <ServiceDetailsText>{t('serviceDetails')}</ServiceDetailsText>
          <PermissionWrapper>
            <PermissionText dangerouslySetInnerHTML={{ __html: t('permissionText', { bookText: isQuotation ? 'Skicka' : 'Boka' }) }}></PermissionText>
          </PermissionWrapper>
          <StyledButton>
            <Button type="submit" onClick={onBookingSubmit} text={t(`${buttonText}`)} variant="primaryAlt" />
          </StyledButton>
        </SubContainer>
      </MainContainer>
    </ContactDetailsWrapper>
  )
}

export default ContactDetails
