import React, { useState } from 'react'
import DayPicker, { DayModifiers } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { useTranslation } from 'next-i18next'
import { DayPickerWrapper, ModifiersStyles, Wrapper } from './Calendar.styles'
import { useMoveDateContext } from '@/common/context/moveDate/MoveDateProvider'

export interface CalendarProps {
  movingDate?: Date
}

const Calendar = ({ movingDate }: CalendarProps) => {
  const { updateMovingDate } = useMoveDateContext()
  const { t } = useTranslation(['dateNames'])
  const [selectedDay, setSelectedDay] = useState<undefined | Date>(movingDate || undefined)
  const months: string[] = t('MONTHS', { returnObjects: true })
  const weekdaysLong: string[] = t('WEEKDAYS_LONG', { returnObjects: true })
  const weekdaysShort: string[] = t('WEEKDAYS_SHORT', { returnObjects: true })
  const today = new Date()

  const handleDayClick = (day: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled || modifiers.past) {
      return
    }
    setSelectedDay(day)
    updateMovingDate(day)
  }
  const modifiers = {
    today: today,
    sundays: { daysOfWeek: [0] },
  }

  const past = {
    before: today,
  }

  return (
    <Wrapper>
      <DayPickerWrapper>
        <DayPicker
          months={months}
          weekdaysLong={weekdaysLong}
          weekdaysShort={weekdaysShort}
          firstDayOfWeek={1}
          selectedDays={selectedDay}
          onDayClick={handleDayClick}
          disabledDays={{ before: today }}
          modifiers={{ past: [past], ...modifiers }}
          modifiersStyles={ModifiersStyles}
          fromMonth={today}
        />
      </DayPickerWrapper>
    </Wrapper>
  )
}

export default Calendar
