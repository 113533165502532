import React, { useCallback } from 'react'
import { SizeOfAdditionalSpaceText, AddressWrapper, InputWrapper, SelectionWrapper, MainContainer, SubContainer } from './../CurrentAddress/CurrentAddress.styles'
import MovingToIcon from '@/public/images/MovingToIcon.svg'
import Flex from '@/components/atoms/Flex'
import Input from '@/components/atoms/Input/Input'
import Selection from '@/components/molecules/Selection'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { APARTMENT, NO, NO_ELEVATOR, YES } from '@/constants/addressFormConstants'
import MapPin from '@/public/images/MapPin.svg'
import { getElevatorTypes, getLimitedAccessibilityTypes, getResidenceTypes } from '@/common/helpers/SelectionTypes'
import CommonAddressForm from '@/components/organisms/CommonAddressForm/CommonAddressForm'
import H3 from '@/components/atoms/H3'
import TextArea from '@/components/atoms/TextArea'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCurrentStepContext } from '@/common/context/steps/CurrentStepProvider'
import Button from '@/components/atoms/Button'
import { StyledButton } from '../../OrderWidgetTemplate.styles'
import { useMoveDateContext } from '@/common/context/moveDate/MoveDateProvider'
import ArrowRight from '@/public/images/ArrowRight.svg'
import ActivityEnum from '@/common/enums/ActivityEnum'
import MoveClean from '@/public/images/Moveclean.svg'
import useResponsive from '@/constants/hooks/useResponsive'
import { useFormAddressContext } from '@/common/context/addressForm/AddressFormProvider'

export const newAddressDefaultValues = {
  autoCompleteAddress: '',
  residenceType: APARTMENT,
  limitedAccessibility: NO,
  elevatorSize: NO_ELEVATOR,
}

const NewAddress = () => {
  const { t } = useTranslation(['address', 'common'])
  const { currentStep, serviceType, incrementStep } = useCurrentStepContext()
  const { movingDate } = useMoveDateContext()
  const { updateNewAddress } = useFormAddressContext()
  const { isTabletPortraitOrGreater } = useResponsive()
  const methods = useFormContext<AddressInfo>()

  const onNewAddressSubmit = methods.handleSubmit(async (data: AddressInfo) => {
    updateNewAddress(data)
    incrementStep()
  })

  const {
    watch,
    setValue,
    formState: { errors },
    register,
    getValues,
  } = methods

  const onSelectResidenceType = useCallback(
    (value: 'apartment' | 'townhouse' | 'house' | string) => {
      setValue('residenceType', value)
    },
    [watch('residenceType')]
  )
  const onSelectElevatorSize = useCallback(
    (value: 'large' | 'small' | 'none' | string) => {
      setValue('elevatorSize', value)
    },
    [watch('elevatorSize')]
  )
  const onSelectLimitedAccessibility = useCallback(
    (value: string) => {
      setValue('limitedAccessibility', value)
    },
    [watch('limitedAccessibility')]
  )

  return (
    <AddressWrapper>
      <MainContainer>
        <SubContainer>
          <Flex
            justifyContent={isTabletPortraitOrGreater ? 'start' : 'center'}
            alignItems={isTabletPortraitOrGreater ? 'end' : 'center'}
            direction={isTabletPortraitOrGreater ? 'row' : 'column'}
          >
            <div className="iconContainer">{serviceType === ActivityEnum.MOVECLEAN ? <MoveClean width={57} height={57} /> : <MovingToIcon />}</div>
            <div className="titleTextContainer">
              <p className="titleText">{serviceType === ActivityEnum.MOVECLEAN ? t('NEWADDRESS.yourAddress') : t('NEWADDRESS.newResidence')}</p>
            </div>
          </Flex>
          <FormProvider {...methods}>
            <CommonAddressForm frontIcon={<MapPin />} />
          </FormProvider>

          <div className="inputWrapper">
            <Selection data={getResidenceTypes(t)} selectedValue={watch('residenceType')} onSelectValue={onSelectResidenceType} />
          </div>

          <InputWrapper>
            <Flex direction="row" justifyContent="space-between" alignItems="center">
              <H3 className="noMarginBottom">{t('residenceSize')}</H3>
              <div className="smallerInput">
                <Input
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  label={t<string>('address:sqm')}
                  {...register('residenceSize', { valueAsNumber: true })}
                  error={errors.residenceSize?.message}
                  defaultValue={isNaN(getValues().residenceSize) ? undefined : getValues().residenceSize}
                />
              </div>
            </Flex>
          </InputWrapper>

          <InputWrapper>
            <Flex direction="row" justifyContent="space-between" alignItems="center">
              <Flex direction="column" justifyContent="start" alignItems="center">
                <SizeOfAdditionalSpaceText>
                  <H3>{t('sizeOfAdditionalSpace')}</H3>
                  <p>{t('sizeOfAdditionalSpaceExample')}</p>
                </SizeOfAdditionalSpaceText>
              </Flex>
              <div className="smallerInput">
                <Input
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  label={t<string>('address:sqm')}
                  {...register('sizeOfAdditionalSpace', { valueAsNumber: true })}
                  error={errors.sizeOfAdditionalSpace?.message}
                  defaultValue={isNaN(getValues().sizeOfAdditionalSpace) ? undefined : getValues().sizeOfAdditionalSpace}
                />
              </div>
            </Flex>
          </InputWrapper>

          {watch('residenceType') === APARTMENT && (
            <>
              <InputWrapper>
                <Flex direction="row" justifyContent="space-between" alignItems="center">
                  <H3 className="noMarginBottom">{t('floor')}</H3>
                  <div className="smallerInput">
                    <Input
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      {...register('floor', { valueAsNumber: true })}
                      error={errors.floor?.message}
                      defaultValue={isNaN(getValues().floor) ? undefined : getValues().floor}
                    />
                  </div>
                </Flex>
              </InputWrapper>

              {/* <InputWrapper>
                <Flex direction="row" justifyContent="space-between" alignItems="center">
                  <H3 className="noMarginBottom">
                    {t('doorCode')}/{t('intercom')}
                  </H3>
                  <div className="doorCodeInput">
                    <Input type="text" {...register('doorCode')} error={errors.doorCode?.message} defaultValue={getValues().doorCode} />
                  </div>
                </Flex>
              </InputWrapper> */}
            </>
          )}
        </SubContainer>
        <SubContainer side="right">
          {watch('residenceType') === APARTMENT && (
            <>
              <SelectionWrapper>
                <InputWrapper className="inputWrapper">
                  <Flex direction="column" justifyContent="space-between" alignItems="start">
                    <H3 className="noMarginBottom">{t('isElevator')}</H3>
                    <p style={{ marginTop: 2, marginBottom: 0 }}>{t('isElevatorText')}</p>
                  </Flex>
                </InputWrapper>

                <InputWrapper className="inputWrapper">
                  <Selection data={getElevatorTypes(t)} selectedValue={watch('elevatorSize')} onSelectValue={onSelectElevatorSize} />
                </InputWrapper>
              </SelectionWrapper>
            </>
          )}
          <InputWrapper className="inputWrapper">
            <Flex direction="column" justifyContent="space-between" alignItems="start">
              <H3 className="noMarginBottom">{t('limitedAccessibility')}</H3>
              <p style={{ marginTop: 2 }}>{t('limitedAccessibilityText')}</p>
            </Flex>
            <Selection data={getLimitedAccessibilityTypes(t)} selectedValue={watch('limitedAccessibility')} onSelectValue={onSelectLimitedAccessibility} />
          </InputWrapper>

          {watch('limitedAccessibility') === YES && (
            <InputWrapper className="inputWrapper">
              <Flex direction="column" justifyContent="space-between" alignItems="center">
                <TextArea
                  {...register('limitedAccessibilityCustomerNotes')}
                  label={t<string>('limitedAccessibilityCustomerNoteLabel')}
                  value={watch('limitedAccessibilityCustomerNotes')}
                />
              </Flex>
            </InputWrapper>
          )}
          <Flex direction={'row'} justifyContent={'center'} className="continueBtn">
            <StyledButton>
              <Button
                onClick={onNewAddressSubmit}
                text={t('common:continue')}
                variant="primaryAlt"
                iconRight={<ArrowRight />}
                disabled={!movingDate && currentStep === 1 ? true : false}
              />
            </StyledButton>
          </Flex>
          <Flex direction={'row'} justifyContent={'center'} alignItems={'center'}>
            <p>
              <strong>{`${t('dateNames:next_step')}: `}</strong>
              {t('summary:PRICE_VIEW.title')}
            </p>
          </Flex>
        </SubContainer>
      </MainContainer>
    </AddressWrapper>
  )
}

export default NewAddress
