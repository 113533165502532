import { styled } from 'stitches.config'

export const ButtonWrapper = styled('div', {
  width: 'auto',
  height: '100%',
  maxWidth: '100%',
  position: 'relative',
})

export const InnerButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  padding: 0,
  borderRadius: '$button',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 700,
  fontSize: '$2',
  variants: {
    variant: {
      primary: {
        background: '$secondaryMain',
        border: '2px solid $secondaryMain',
        color: '$whiteMain',
        '&:active': {
          background: '$secondaryDark',
          border: '2px solid $secondaryDark',
        },
        ' &:hover': {
          background: '$secondaryDark',
          border: '2px solid $secondaryDark',
        },
      },
      ghost: {
        background: 'transparent',
        border: '2px solid $secondaryMain',
        color: '$secondaryMain',
        '&:active': {
          border: '2px solid $secondaryMain',
          background: '$inactiveQuiteLight',
        },
        '&:hover': {
          background: '$inactiveQuiteLight',
          border: '2px solid $secondaryMain',
        },
      },
      ghostInverted: {
        background: 'transparent',
        border: '2px solid $whiteMain',
        color: '$whiteMain',
        '&:active': {
          border: '2px solid $whiteMain',
          background: '$whiteMain',
        },
        '&:hover': {
          background: 'transparent',
          border: '2px solid $whiteMain',
        },
      },
      green: {
        background: '$primaryMain',
        border: '2px solid $primaryMain',
        color: '$whiteMain',
        '&:active': {
          color: '$secondaryMain',
          background: '$inactiveMain',
          border: '2px solid $inactiveMain',
        },
        ' &:hover': {
          color: '$secondaryMain',
          background: '$inactiveMain',
          border: '2px solid $inactiveMain',
        },
      },
      primaryAlt: {
        background: '$accentLight',
        border: '2px solid $accentLight',
        color: '$secondaryMain',
        ' &:active': {
          background: '$accentMain',
        },
        ' &:hover': {
          background: '$accentMain',
          color: '$blackMain',
          border: '2px solid $accentMain',
        },
      },
      primaryAltInverted: {
        background: '$accentMain',
        border: '2px solid $accentMain',
        color: '$whiteMain',
        ' &:active': {
          background: '$accentMain',
        },
        ' &:hover': {
          background: '$accentLight',
          color: '$secondaryMain',
          border: '2px solid $accentLight',
        },
      },
      gray: {
        background: '$inactiveGreyLight',
        border: '2px solid $inactiveGreyLight',
        color: '$whiteMain',
        '&:active': {
          background: '$inactiveMain',
          color: '$inactiveGreyLight',
          border: '2px solid $inactiveMain',
        },
        '&:hover': {
          background: '$inactiveMain',
          color: '$inactiveGreyLight',
          border: ' 2px solid $inactiveMain',
        },
      },
      outline: {
        background: '$whiteMain',
        border: '2px solid $secondaryMain',
        color: '$secondaryMain',
        '&:active': {
          background: '$whiteMain',
          color: '$secondaryMain',
          border: '2px solid $secondaryMain',
        },
        ' &:hover': {
          background: '$whiteMain',
          color: '$secondaryMain',
          border: '2px solid $secondaryMain',
        },
      },
    },
    isDisabled: {
      true: {
        background: '$inactiveMain',
        color: '$inactiveGreyLight',
        border: '2px solid $inactiveMain',
        '&:hover': {
          background: '$inactiveMain',
          color: '$inactiveGreyLight',
          cursor: 'unset',
          border: '2px solid $inactiveMain',
        },
      },
    },
  },
  '&:hover': {
    cursor: 'pointer',
  },
})

export const IconLeftWrapper = styled('div', {
  position: 'relative',
  marginRight: 10,
  zIndex: 1,
})

export const IconRightWrapper = styled('div', {
  position: 'relative',
  marginLeft: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
  width: 20,
  height: 20,
})
