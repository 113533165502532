import { styled } from 'stitches.config'

export const Label = styled('label', {
  display: 'grid',
  gridTemplateColumns: '1em auto',
  gap: '0.5em',
  alignItems: 'center',

  '&.disabled': {
    color: '$inactiveText',
  },
})

export const Input = styled('input', {
  /* Add if not using autoprefixer */
  '-webkit-appearance': 'none',
  /* Remove most all native input styles */
  appearance: 'none',
  /* For iOS < 15 */
  backgroundColor: '$whiteMain',
  /* Not removed via appearance */
  margin: 0,

  font: 'inherit',
  color: 'currentColor',
  width: '1.5em',
  height: '1.5em',
  border: '0.15em solid #9f9fa1',
  borderRadius: 4,
  transform: 'translateY(-0.075em)',

  display: 'grid',
  placeContent: 'center',

  '&:checked': {
    background: '#71B6ED',
    borderColor: '#71B6ED',
  },

  '&::before': {
    content: '""',
    width: '14px',
    height: '10px',
    clipPath:
      "path(evenodd, 'M12.9017 1.13434C13.3798 1.63235 13.3637 2.42364 12.8657 2.90174L6.61567 8.90174C6.13196 9.36609 5.36804 9.36609 4.88434 8.90174L1.13434 5.30174C0.636323 4.82364 0.620174 4.03235 1.09827 3.53434C1.57636 3.03632 2.36765 3.02017 2.86567 3.49827L5.75 6.26723L11.1343 1.09827C11.6324 0.620174 12.4236 0.636323 12.9017 1.13434Z')",
    transform: 'scale(0)',
    transformOrigin: 'bottom left',
    transition: '120ms transform ease-in-out',
    boxShadow: 'inset 1em 1em white',
    /* Windows High Contrast Mode */
    backgroundColor: 'CanvasText',
  },

  '&:checked::before': {
    transform: 'scale(1)',
  },

  '&:focus': {
    outline: '2px solid #71B6ED',
    outlineOffset: '0px',
  },

  '&:disabled': {
    borderColor: '$inactiveGreyLight',
    background: '$whiteMain',
  },

  '&:checked:disabled': {
    borderColor: '$inactiveGreyLight',
    background: '$inactiveGreyLight',
  },
})

export const Error = styled('div', {
  color: '$errorText',
  fontSize: '$1',
})