import React, { ReactElement } from 'react'
import { ButtonWrapper, InnerButton, IconLeftWrapper, IconRightWrapper } from './Button.style'

export type ButtonProps = {
  onClick: React.MouseEventHandler
  text: string | null
  disabled?: boolean
  variant?: 'primary' | 'ghost' | 'ghostInverted' | 'primaryAlt' | 'primaryAltInverted' | 'gray' | 'outline' | 'green'
  iconLeft?: ReactElement | null
  iconRight?: ReactElement | null
  alt?: string
  dataTestId?: string
  opacity?: number
  fontSize?: number
  padding?: string
  type?: 'submit' | 'reset' | 'button'
  isLoading?: boolean
}

const Button = ({
  onClick,
  text,
  disabled = false,
  variant = 'primary',
  iconLeft,
  iconRight,
  dataTestId = '',
  opacity = 1,
  fontSize = 16,
  padding = '',
  type,
  isLoading,
}: ButtonProps) => {
  return (
    <ButtonWrapper onClick={(e) => !disabled && onClick(e)} data-testid={dataTestId}>
      {isLoading && <p>Insert SPINNER COMPONENT LATER!</p>}
      <InnerButton itemType="button" disabled={disabled} variant={variant} css={{ fontSize: fontSize, opacity: opacity, padding: padding }} type={type} isDisabled={disabled}>
        {iconLeft && <IconLeftWrapper data-testid="iconRight">{iconLeft}</IconLeftWrapper>}
        {/* Insert SPINNER COMPONENT LATER! */}
        {text}
        {iconRight && <IconRightWrapper data-testid="iconRight">{iconRight}</IconRightWrapper>}
      </InnerButton>
    </ButtonWrapper>
  )
}

export default Button
