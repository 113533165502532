import React from 'react'
import { styled } from 'stitches.config'

export type TextAreaProps = {
  label?: string
  error?: string
  endIcon?: React.ReactNode
} & Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'type'>

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(({ label, error, endIcon, ...props }, ref) => {
  const { value } = props
  return (
    <InputWrapper>
      <TextAreaStyled hasValue={!!value} ref={ref} error={!!error} {...props} />
      <Label className="label">
        <span>{label}</span>
      </Label>
      {endIcon && <Icon>{endIcon}</Icon>}
      {error && <ErrorText>{error}</ErrorText>}
    </InputWrapper>
  )
})

export default TextArea

const Icon = styled('div', {
  position: 'absolute',
  top: 6,
  right: 8,
})

const Label = styled('label', {
  position: 'absolute',
  left: '11px',
  top: '7.5px',
  color: 'gray',
  fontWeight: 400,
  fontSize: '1rem',
  pointerEvents: 'none',
  display: 'flex',
  transition: '0.2s ease',
  '&:before': {
    transition: 'all 0.2s ease',
    position: 'absolute',
    height: '50%',
    width: '100%',
    content: '',
  },
  '&:after': {
    transition: 'all 0.2s ease',
    position: 'absolute',
    height: '50%',
    width: '100%',
    content: '',
    top: '50%',
  },
})

const ErrorText = styled('div', {
  color: '$errorRed',
  position: 'absolute',
  fontSize: '12px',
})

const TextAreaStyled = styled('textarea', {
  fontFamily: 'Gilroy',
  height: '80px',
  width: ' 100%',
  padding: '14px 10px',
  outline: 'none',
  borderRadius: '$input',
  border: '1.9px solid rgba(118, 118, 118, 0.4)',
  fontWeight: 100,
  fontSize: '16px',
  transition: 'border 10ms',
  resize: 'vertical',
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: '0',
  },
  color: 'rgba(0, 0, 0, 0.7)',
  '&:focus': {
    border: '2px solid $tertiaryMain',
    transition: 'border 10ms',
  },
  '&:focus + .label': {
    top: '-7px',
    fontSize: '12px',
    backgroundColor: ' white',
    padding: '0 2px',
    color: '$tertiaryMain',
    left: '10px',
  },
  variants: {
    hasValue: {
      true: {
        border: '1.9px solid rgba(118, 118, 118, 0.4)',
        transition: 'border 10ms',
        '+ .label': {
          top: '-7px',
          fontSize: '12px',
          backgroundColor: 'white',
          padding: '0 2px',
          color: 'rgba(0, 0, 0, 0.7)',
          left: '10px',
        },
      },
    },
    error: {
      true: {
        border: '1.9px solid $errorRed',
        transition: 'border 10ms',
        '+ .label': {
          top: '-7px',
          fontSize: '12px',
          backgroundColor: 'white',
          padding: '0 2px',
          color: '$errorRed',
          left: ' 10px',
        },
      },
    },
  },
})

const InputWrapper = styled('div', {
  width: '100%',
  maxWidth: '100%',
  position: 'relative',
  boxSizing: 'border-box',
  height: '100%',
})
