import { styled } from 'stitches.config'

export const MainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  margin: '0 0 20px 0',
  '@sm': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: '0 0px 0 0px',
    '.left': {
      marginTop: '100px',
    },
  },

  H2: {
    color: '$secondaryMain',
  },
  p: {
    color: '$secondaryMain',
    fontSize: '$5',
    lineHeight: '20px',
  },
  '.titleText': {
    fontStyle: 'normal',
    fontWeight: '$bold',
    fontSize: '36px',
    lineHeight: '1',
    marginBottom: '$3',
  },
  '.infotext1': {
    color: '#214766',
    marginTop: '10px',
  },
  '.infotext2': {
    fontWeight: '$bold',
    marginTop: '10px',
  },

  '.currentAddress': {
    marginLeft: '2px',
  },
})

export const SubContainer = styled('div', {
  '@sm': {
    flexBasis: '30em',
    margin: '0 10px 0 10px',
    padding: '0 15px 0 15px',
    '.infoTextContainer': {
      textAlign: 'left',
    },
  },
})
