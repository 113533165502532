import React, { memo } from 'react'
import { ButtonsWrapper, SelectionWrapper, SelectionButtonWrapper } from './Selection.styles'

interface SelectionData {
  name: string
  displayName: string
}

export interface SelectionProps {
  data: SelectionData[]
  selectedValue: string
  onSelectValue: (value: string) => void
}

const Selection = ({ data, selectedValue, onSelectValue }: SelectionProps) => {
  return (
    <SelectionWrapper>
      <ButtonsWrapper>
        {data.map((d) => (
          <SelectionButtonWrapper key={d.name} selected={selectedValue === d.name} onClick={() => onSelectValue(d.name)} data-testid="selection-text">
            {d.displayName}
          </SelectionButtonWrapper>
        ))}
      </ButtonsWrapper>
    </SelectionWrapper>
  )
}

export default memo(Selection)
